import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isLoggedIn = false;
  @Input() loggedin: boolean;
  constructor(private router: Router) {}

  ngOnInit(): void {}
  back() {
    this.router.navigate(['user-info']);
  }
}
