<form [formGroup]="formname">
  <div *ngFor="let q of ques; let i = index; let last = last">
    <div
      id="subhead"
      aria-required="true"
      *ngIf="qno == i && this.formname.controls[q.question_key].value"
      class="card-block display-flex shadow"
    >
      <b class="subhead">
        <b>{{ subhead }}</b>
      </b>
      <div class="qnodiv">{{ qno + 1 }} of {{ lastno + 1 }}</div>
      <div>
        <h5 class="question">
          <b>Q. {{ q.question }}&nbsp;</b>
        </h5>
      </div>
      <div>
        <div
          class="form-check"
          *ngFor="let option of q.options"
          [class.selected]="
            this.formname.controls[q.question_key].value != null &&
            (this.formname.controls[q.question_key].value == option.value ||
              this.formname.controls[q.question_key].value.includes(
                option.value
              ) ||
              storedobj[q.question_key].answer_key.includes(option.index))
          "
        >
          <input
            class="form-check-input"
            id="{{ q.question_key }}"
            type="{{ q.type }}"
            disabled
            [checked]="
              this.formname.controls[q.question_key].value.includes(
                option.value
              ) || storedobj[q.question_key].answer_key.includes(option.index)
            "
            *ngIf="q.type == 'checkbox'"
            [value]="option.value"
            name="{{ q.question_key }}"
          />

          <input
            class="form-check-input"
            id="{{ q.question_key }}"
            type="{{ q.type }}"
            disabled
            [checked]="
              this.formname.controls[q.question_key].value == option.value
            "
            *ngIf="q.type == 'radio'"
            [value]="option.value"
            name="{{ q.question_key }}"
          />
          <span>{{ option.value }}</span>
        </div>
        <input
          *ngIf="
            storedobj[q.question_key] &&
            storedobj[q.question_key].tag &&
            storedobj[q.question_key].tag.includes('nan') &&
            !other
          "
          [value]="this.formname.controls[q.question_key].value[0]"
          disabled
          readonly
          class="form-control text-field"
          type="text"
          name="{{ q.question_key }}"
        />
        <br />
        <input
          type="text"
          maxlength="250"
          [value]="
            storedobj[q.question_key].recommendation
              ? storedobj[q.question_key].recommendation
              : null
          "
          (input)="updateValue($event, q.question_key, q.question)"
          name="{{ q.question_key }}"
          class="form-control text-field"
          placeholder="Please specify recommendation"
        />
        <!-- <input
          *ngIf="storedobj[q.question_key].recommendation"
          (input)="updateValue($event, q.question_key, q.question)"
          class="form-control text-field"
          type="text"
          name="{{ q.question_key }}"
        /> -->
      </div>
      <div class="nav-buttons">
        <button
          [disabled]="othersText"
          (click)="nextq()"
          *ngIf="qno != lastno"
          class="next"
        >
          Next
          <img class="checkbox-svg" src="../../assets/images/right-arrow.svg" />
        </button>
        <button
          [disabled]="othersText"
          (click)="open(terms)"
          *ngIf="qno == lastno"
          class="next"
        >
          Next
          <img class="checkbox-svg" src="../../assets/images/x-square.png" />
        </button>
        &nbsp;
        <button (click)="prevq()" *ngIf="qno != 0" class="prev">
          <img class="checkbox-svg" src="../../assets/images/left-arrow.svg" />
          Previous
        </button>
      </div>
    </div>
  </div>
  <ng-template #terms let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ subhead }} Recommendation
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <input
        style="width: 100%; margin-left: 0%; margin-top: 15px"
        (input)="updateCat($event, subhead)"
        maxlength="250"
        type="text"
        name="{{ subhead }}"
        [value]="
          catResponse && catResponse[subhead] ? catResponse[subhead] : null
        "
        class="form-control text-field"
        placeholder="Please specify category recommendation"
      />
      <!-- <input
    style="width: 100%; margin-left: 0%; margin-top: 15px"
      (input)="updateCat($event, subhead)"
      maxlength="250"
      type="text"
      name="{{ subhead }}"
      class="form-control text-field"
      placeholder="Please specify category recommendation"
      *ngIf=""
    /> -->
    </div>
    <div class="modal-footer">
      <div style="float: right; margin-right: 9%">
        <!-- <button (click)="prevq()" *ngIf="x != 0" class="btn btn-primary mt-5">
          <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
          Previous: {{ cat[x - 1] }}
        </button> -->
        &nbsp;
        <button
          [disabled]="!isValid"
          (click)="nextqCat()"
          *ngIf="x != lastindex"
          class="next"
        >
          Next
          <img class="checkbox-svg" src="../../assets/images/x-square.png" />
        </button>
        &nbsp;
        <button
          [disabled]="!isValid"
          (click)="next()"
          *ngIf="x == lastindex"
          class="next info-button"
        >
          Additonal Information
          <img class="checkbox-svg" src="../../assets/images/x-square.png" />
        </button>
      </div>
    </div>
  </ng-template>
</form>
