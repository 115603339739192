<div id="stepper1" class="bs-stepper">
  <div class="bs-stepper-header" style="display: none">
    <div class="step" data-target="#test-l-1">
      <button class="step-trigger" (click)="step1()">
        <span class="bs-stepper-circle">1</span>
        <span class="bs-stepper-label">Select an assessment </span>
      </button>
    </div>
    <div class="line"></div>
    <div class="step" data-target="#test-l-2">
      <button [disabled]="!firstStepCompleted" class="step-trigger">
        <span class="bs-stepper-circle">2</span>
        <span class="bs-stepper-label">Answer the questions</span>
      </button>
    </div>
    <div class="line"></div>
    <div class="step" data-target="#test-l-3">
      <button
        [disabled]="!secondStepCompleted"
        class="step-trigger"
        style="display: none"
      >
        <span class="bs-stepper-circle">3</span>
        <span class="bs-stepper-label">Additional Information</span>
      </button>
    </div>
    <!-- <div class="line"></div> -->
    <div class="step" data-target="#test-l-4">
      <button [disabled]="!thirdStepCompleted" class="step-trigger">
        <span class="bs-stepper-circle">3</span>
        <span class="bs-stepper-label">Get your report </span>
      </button>
    </div>
  </div>
  <div class="">
    <div class="form-group">
      <div id="test-l-1" class="content">
        <app-header></app-header>
        <div class="container">
          <h1 class="heading">
            <b>Recommendation</b>
          </h1>
          <div *ngIf="apiloading" class="d-flex justify-content-center">
            <div class="" role="status">
              <img class="square-loader" src="../../assets/images/loader.svg" />
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div *ngIf="!apiloading">
            <div class="row">
              <table class="table" *ngIf="assessmentList.length > 0">
                <tr>
                  <th class="th" style="width: 30%">User</th>
                  <th class="th" style="width: 30%">Category</th>
                  <th class="th" style="width: 20%">Date</th>
                  <th class="th" style="width: 20%">Time</th>
                </tr>
                <tr
                  *ngFor="let assessment of assessmentList; let i = index"
                  class="transform tr"
                >
                  <td
                    class="td"
                    *ngIf="assessment.assessment_status == 'draft'"
                    (click)="
                      selectAssessment(
                        assessment.category_name,
                        assessment.id,
                        assessment.email,
                        assessment.category_id,
                        assessment.name
                      )
                    "
                  >
                    {{ assessment.email }}
                  </td>
                  <td
                    class="td"
                    *ngIf="assessment.assessment_status == 'draft'"
                    (click)="
                      selectAssessment(
                        assessment.category_name,
                        assessment.id,
                        assessment.email,
                        assessment.category_id,
                        assessment.name
                      )
                    "
                  >
                    {{ assessment.category_name }}
                  </td>
                  <td
                    class="td"
                    *ngIf="assessment.assessment_status == 'draft'"
                    (click)="
                      selectAssessment(
                        assessment.category_name,
                        assessment.id,
                        assessment.email,
                        assessment.category_id,
                        assessment.name
                      )
                    "
                  >
                    {{ assessment.date }}
                  </td>
                  <td
                    class="td"
                    *ngIf="assessment.assessment_status == 'draft'"
                    (click)="
                      selectAssessment(
                        assessment.category_name,
                        assessment.id,
                        assessment.email,
                        assessment.category_id,
                        assessment.name
                      )
                    "
                  >
                    {{ assessment.time }}
                  </td>
                </tr>
              </table>

              <br />
              <h3>
                <b>
                  <center *ngIf="assessmentList.length == 0">
                    <br /><br /><br /><br />No Draft Assessment Available
                  </center>
                </b>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div id="test-l-2" class="content">
        <app-header></app-header>
        <div class="container">
          <h1 class="heading">
            <b>Recommendation</b>
          </h1>
          <div *ngIf="apiloading" class="d-flex justify-content-center">
            <div class="" role="status">
              <img class="square-loader" src="../../assets/images/loader.svg" />
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div *ngIf="!apiloading">
            <div *ngFor="let s of stepperlist; index as i">
              <div *ngIf="i == x">
                <app-custom-questions
                  [formname]="cat[x]"
                  [userfrm]="userForm"
                  [subhead]="cat[x]"
                  [ques]="s"
                  (formvalid)="valid($event)"
                  (compform)="strfrm($event)"
                >
                </app-custom-questions>
              </div>
            </div>
            <div class="blank-text" *ngIf="stepperlist.length == 0">
              Not Attempted
            </div>
            <br />
            <div style="float: left; margin-left: 9%">
              &nbsp;
              <button (click)="stepBack()" *ngIf="x == 0" class="prev">
                <img
                  class="select-svg"
                  src="../../assets/images/left-arrow.svg"
                />Select Assessment
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div id="test-l-3" class="content">
        <app-header></app-header>
        <h1 class="heading">
          <b>Recommendation</b>
        </h1>

        <div class="card-block shadow">
          <b class="subhead">Additional information (optional)</b>
          <p class="addText">
            Please mention any other relevant details about your existing
            infrastructure or technology stack that you would like to share with
            us.
          </p>
          <br />

          <div class="form-group">
            <textarea
              [value]="inputValue"
              disabled
              readonly
              class="“form-control”"
              id="addinfo"
              #addinfo
              rows="3"
              style="
                margin-right: 5%;
                margin-left: 5%;
                margin-top: 15px;
                max-width: 90%;
                min-width: 90%;
                padding: 10px;
              "
            ></textarea>
            <textarea
              class="“form-control”"
              id="addrecom"
              #addrecom
              rows="3"
              style="
                margin-right: 5%;
                margin-left: 5%;
                margin-top: 15px;
                max-width: 90%;
                min-width: 90%;
                padding: 10px;
              "
            ></textarea>
          </div>

          <p class="addText">
            <b>Disclaimer</b> - The additional Information captured here will
            not have an impact on your assessment report. It is only captured to
            gain a deeper understanding of your requirements.
          </p>
          <br />

          <div class="button-section">
            <div class="assessment-form">
              &nbsp;
              <button type="submit" (click)="stepBack()" class="prev">
                <img
                  class="checkbox-svg"
                  src="../../assets/images/left-arrow.svg"
                />
                <span class="" aria-hidden="true">Assessment Form</span>
              </button>
            </div>
            <div class="next-section">
              &nbsp;
              <button
                type="submit"
                #addrecom
                (click)="submit2(addrecom.value, inputValue)"
                class="next"
              >
                <span class="" aria-hidden="true"
                  >Submit Recommendation
                  <img
                    class="checkbox-svg"
                    src="../../assets/images/x-square.png"
                /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="test-l-4" class="content">
        <div *ngIf="isReport">
          <app-custom-report></app-custom-report>
        </div>
      </div>
    </div>
  </div>
</div>
