import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterContentInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { QuestionService } from '../question.service';
import { QuestionBase } from './../question-base';
import { QuestionControlService } from './../question-control.service';
import { GetQuestionsService } from 'src/app/shared-services/get-questions.service';
import { AssessmentComponent } from '../assessment.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  providers: [QuestionControlService],
})
export class QuestionComponent implements OnInit, AfterContentInit {
  @Input() ques: any;
  @Input() formname: any;
  @Input() subhead: string;
  @Output() formvalid = new EventEmitter<boolean>();
  @Output() compform = new EventEmitter<any>();
  @Output() queno = new EventEmitter<any>();
  @Output() lno = new EventEmitter<any>();
  @Input() userfrm: any;
  othersText = false;
  qno = 0;
  lastno = 0;
  state: boolean;
  other: boolean = false; // to enable input text field
  storedobj: any; //hold entire stored object in GetQuestionsService
  current_que: string; // hold current question
  index = {};
  tag = {};
  x: any;
  constructor(
    private formBuilder: FormBuilder,
    private qcs: QuestionControlService,
    private qs: QuestionService,
    private gqs: GetQuestionsService,
    private router: Router,
    private asc: AssessmentComponent
  ) {
    this.ques = qs.getQuestions();
  }
  /**
   * checking validation of form
   */
  isValid() {
    let t = this.gqs.returnobj();
    if (JSON.stringify(t) === JSON.stringify({})) {
      this.compform.emit(this.formname);
      this.formvalid.emit(false);
      this.lno.emit(this.lastno);
      this.queno.emit(this.qno);
      return;
    } else {
      for (const key in t) {
        if (t[key].answer[0] == null) {
          this.compform.emit(this.formname);
          this.formvalid.emit(false);
          this.queno.emit(this.qno);
          this.lno.emit(this.lastno);
          return;
        }
      }
    }
    this.formvalid.emit(true);
    this.queno.emit(this.qno);
    this.lno.emit(this.lastno);
    this.compform.emit(this.formname);
    return;
  }
  ngAfterContentInit() {
    this.queno.emit(this.qno);
    this.lno.emit(this.lastno);
  }

  ngOnInit() {
    this.x = this.asc.x;
    this.lastno = this.ques.length - 1;
    this.formname = this.qcs.toFormGroup(this.ques as QuestionBase<string>[]);
    this.storedobj = this.gqs.returnobj();
    //initializing index and tags object if they exists
    try {
      if (this.storedobj) {
        for (let key in this.storedobj) {
          this.index[key] = this.storedobj[key].answer_key;
          this.tag[key] = this.storedobj[key].tag;
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.isValid();
  }
  /**
   * Updating form object for radio option selection
   * @param object //unique question key
   * @param answer //option value
   * @param question //question
   * @param tag //option tag
   * @param index //option index
   */
  updateradio(object: string, answer: string, question: string, index, tag) {
    //Handling others option
    if (tag == 'nan') {
      this.other = true;
      this.othersText = true;
      this.current_que = question;
      this.formname.controls[object].value = [null];
      this.index[object] = [];
      this.index[object].push(index);
      this.tag[object] = [];
      this.tag[object].push(tag);
      // console.log(this.othersText)
    } else {
      this.other = false;
      this.othersText = false;
      this.tag[object] = [];
      this.tag[object].push(tag);
      this.current_que = question;
      this.formname.controls[object].value = answer;
      // console.log(this.othersText)
    }
    // Response object
    let res = {
      question: question,
      answer: this.formname.controls[object].value,
      subCategory: this.subhead,
      question_key: object,
      answer_key: [index],
      tag: this.tag[object],
    };
    this.gqs.storeAttempted(this.subhead, object, this.ques.length);
    this.storedobj = this.gqs.storeobj(object, res);
    this.isValid();
  }
  /**
   * Updating form object for others option selection and
   * Halding Text input from others option
   * @param event //selection event
   * @param object //unique question key
   * @param question //question
   */
  updateValue(event, object: string, question: string) {
    if (event.target.value == '' || event.target.value.trim() == '') {
      this.othersText = true;
      // console.log('in true',this.othersText)
      this.formname.controls[object].value = [null];
    } else {
      this.othersText = false;
      // console.log('in false',this.othersText)
      this.formname.controls[object].value = [event.target.value.trim()];
    }
    // Response object
    let res = {
      question: question,
      answer: this.formname.controls[object].value,
      subCategory: this.subhead,
      question_key: object,
      answer_key: this.index[object],
      tag: this.tag[object],
    };
    // console.log(res)
    this.storedobj = this.gqs.storeobj(object, res);
    this.gqs.storeAttempted(this.subhead, object, this.ques.length);
    this.isValid();
  }
  /**
   * Updating form object for checkbox option selection
   * @param object //unique question key
   * @param answer //option value
   * @param question //question
   * @param tag //option tag
   * @param index //option index
   */
  updatecheckbox(
    object: string,
    answer: string,
    question: string,
    tag: string,
    index
  ) {
    // For Handling selection of others option
    if (tag == 'nan') {
      this.other = true;
      this.othersText = true;
      this.current_que = question;
      //Re-selection of others option
      if (
        this.formname.controls[object].value != null &&
        this.storedobj[object].answer_key.includes(index)
      ) {
        this.other = false;
        this.othersText = false;
        this.formname.controls[object].value = this.formname.controls[
          object
        ].value = null;
        this.index[object] = [];
        this.tag[object] = [];
        this.gqs.popobj(object, this.subhead);
        this.isValid();
        return;
      } else {
        this.formname.controls[object].value = [null];
        this.index[object] = [];
        this.index[object].push(index);
        this.tag[object] = [];
        this.tag[object].push(tag);
      }
    }
    // For Handling any other option selection
    else {
      if (this.formname.controls[object].value == null) {
        this.formname.controls[object].value = [];
        this.formname.controls[object].value.push(answer);
        this.index[object] = [];
        this.index[object].push(index);
        if (tag) {
          this.tag[object] = [];
          this.tag[object].push(tag);
        }
      } else {
        //Removing others option if exists
        if (this.tag[object] && this.tag[object].includes('nan')) {
          this.formname.controls[object].value = this.formname.controls[
            object
          ].value = [];
          this.index[object] = [];
          this.tag[object] = [];
          if (tag) {
            this.tag[object].push(tag);
          }
        }
        //Re-selction of same option
        if (this.formname.controls[object].value.includes(answer)) {
          this.formname.controls[object].value = this.formname.controls[
            object
          ].value.filter((item) => item !== answer);
          this.index[object] = this.index[object].filter(
            (item) => item !== index
          );
          if (this.tag[object]) {
            this.tag[object] = this.tag[object].filter(
              (item) => item !== index
            );
          }

          if (this.formname.controls[object].value.length == 0) {
            this.formname.controls[object].value = null;
            this.index[object] = [];
            this.tag = [];
            this.gqs.popobj(object, this.subhead);
            this.isValid();
            return;
          }
        } else {
          // Adding option if not already exists
          this.formname.controls[object].value.push(answer);
          this.index[object].push(index);
          if (tag) {
            this.tag[object].push(tag);
          }
        }
      }
      this.other = false;
      this.othersText = false;
      this.current_que = question;
    }
    // Response object
    let res = {
      question: question,
      answer: this.formname.controls[object].value,
      subCategory: this.subhead,
      question_key: object,
      answer_key: this.index[object],
      tag: this.tag[object],
    };
    this.storedobj = this.gqs.storeobj(object, res);
    this.gqs.storeAttempted(this.subhead, object, this.ques.length);
    this.isValid();
  }

  prevq() {
    this.qno--;
    this.queno.emit(this.qno);
    this.lno.emit(this.lastno);
    let temp = this.ques[this.qno];
    this.current_que = temp.question;
    if (
      this.storedobj[temp.question_key] &&
      this.storedobj[temp.question_key].tag &&
      this.storedobj[temp.question_key].tag.includes('nan')
    ) {
      this.other = true;
    } else {
      this.other = false;
    }
  }
  nextq() {
    this.qno++;
    this.queno.emit(this.qno);
    this.lno.emit(this.lastno);
    let temp = this.ques[this.qno];
    this.current_que = temp.question;
    if (
      this.storedobj[temp.question_key] &&
      this.storedobj[temp.question_key].tag &&
      this.storedobj[temp.question_key].tag.includes('nan')
    ) {
      this.other = true;
    } else {
      this.other = false;
    }
  }

  nextCat() {
    this.asc.nextq();
  }
  stepBackSelect() {
    this.asc.initial();
    this.router.navigate(['select-assessment']);
  }
}
