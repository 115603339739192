import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import {
  GetQuestionsService,
  IGraphUrls,
} from '../shared-services/get-questions.service';
import { NotifierToastService } from '../shared-services/notifier-toast.service';

import { environment } from 'src/environments/environment';
import { ISubmitResponse, ICategory } from './assessment.utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AssessmentComponent implements OnInit {
  isAddInfo = false;
  apiloading = false; //For loader state
  categoryList: ICategory[];
  result: any;
  stepperlist = []; // For sub_stepper
  x; //For holding current index of sub_stepper
  cat = []; //For holding sub categories
  lastindex = 0; //For total number of sub_category
  frm: any;
  rating: any;
  reportlink: string; // For final report link
  selectedcat: string; //For user selected category
  message: string; // For final report message
  @Input() form: any;
  state: boolean; //For holding validation state of sub_stepper
  emailloading: boolean;
  user_email: any;
  htmlString; // For recommendation table
  date; //Date on which assessment is taken
  radarUrl: string;
  barGraphUrl: string;
  additionalInfo: any;
  isReportGenerated = false;
  previewCategory: string = null;
  categoryTypes = {
    cloudMigration: 'Cloud Migration Readiness Assessment',
    cloudArchitecture: 'Cloud Architecture Maturity Assessment',
    cloudEnablement: 'Cloud Enablement Readiness Assessment',
  };
  lastno = 10; //dummy value to avoid undefined error
  qno = 0; //dummy value to avoid undefined error
  selectedSubCat: any;
  comparedAttempted: any;
  attempted: any;
  allSubCat = [];

  constructor(
    private getQues: GetQuestionsService,
    private router: Router,
    private nts: NotifierToastService
  ) {
    this.apiloading = true;
  }
  /**
   * checking validation of sub steppers
   */
  valid(currentstate: boolean) {
    this.state = currentstate;
  }
  strfrm(frm: any) {
    this.frm = frm;
  }
  queno(queno: any) {
    this.comparedAttempted = this.getQues.compareAttempted();
    this.attempted = this.getQues.returnAttemptedObj();
    this.qno = queno;
  }
  lno(lno: any) {
    this.lastno = lno;
  }

  get isValid() {
    return this.state;
  }
  /**
   * next question category sub_stepper
   */
  nextq() {
    this.getQues.storeCurrentSubCat(this.cat, this.x);
    let flag = 0;
    for (const key in this.attempted) {
      if (Object.prototype.hasOwnProperty.call(this.attempted, key)) {
        const element = this.attempted[key];
        if (this.attempted[key].attempted.length == this.attempted[key].total) {
          flag = flag + 1;
          // console.log('calculting')
        }
      }
    }
    // console.log(flag, this.cat.length);
    if (flag == this.cat.length) {
      // this.router.navigate(['assessment'], {
      //   state: {
      //     isAddInfo: true,
      //   },
      // });
      history.state.isAddInfo = true;
      this.ngOnInit();
    } else {
      this.router.navigate(['select-subcategory']);
    }
    // this.state = false;
    window.scroll(0, 0);
  }
  /**
   * previous stepper
   */
  stepBack() {
    window.scroll(0, 0);
    this.x = 0;
  }
  stepBackSelect() {
    this.initial();
    this.router.navigate(['select-assessment']);
  }
  /**
   * previous question category sub_stepper
   */
  prevq() {
    this.x = this.x - 1;
    window.scroll(0, 0);
  }

  /**
   * Intiallizing steppers
   */
  ngOnInit() {
    this.message = '';
    let name, id;
    let temp = this.getQues.returnInitialCat();
    this.categoryList = temp[0];
    name = temp[1];
    id = temp[2];
    this.selectedcat = name;
    this.selectAssessment(name, id);
    this.x = this.getQues.returnNextSubCat();
    this.attempted = this.getQues.returnAttemptedObj();
    this.comparedAttempted = this.getQues.compareAttempted();
    if (Object.keys(this.comparedAttempted).length === 0) {
      this.cat.forEach((ele) => {
        this.comparedAttempted[ele] = '#ffcece';
      });
    }
    this.isAddInfo = history.state.isAddInfo;
  }
  initial() {
    this.additionalInfo = '';
    if (document.getElementById('addinformation')) {
      (document.getElementById('addinformation') as HTMLInputElement).value =
        '';
    }
    this.isReportGenerated = false;
    this.getQues.initial();
  }

  /**
   *
   * Fetching assessment corresponding to selected category
   * @param Category // Category of selected draft assessment
   */
  async selectAssessment(Category: string, id) {
    this.message = '';
    this.selectedcat = Category;
    this.apiloading = true;
    this.user_email = this.getQues.getUser();
    //API call for ques in user selected category
    (await this.getQues.getQues(Category, id)).subscribe(
      (data: any) => {
        this.apiloading = false;
        this.result = data.assessments;
        this.x = this.getQues.returnNextSubCat();
        // Array for all sub_category
        this.cat = Object.keys(this.result);
        this.getQues.storeCurrentSubCat(this.cat, this.x);
        this.lastindex = this.cat.length - 1;
        let i = 0;
        this.cat.forEach((element) => {
          let stepresult = this.result[element];
          //Array of question grouped by sub_category for sub_steppers
          this.stepperlist[i] = stepresult;
          this.allSubCat.push({
            subCategoryName: element,
            subCategoryId: stepresult[0].sub_category_id,
          });
          i = i + 1;
        });
        this.getQues.storeAllcategories(this.allSubCat);
        if (this.x == 0) {
          if (Object.keys(this.comparedAttempted).length === 0) {
            this.cat.forEach((ele) => {
              this.comparedAttempted[ele] = '#ffcece';
            });
          }
        }
      },
      (err: Error) => {
        console.log(err);
        this.nts.showNotification(
          'error',
          'Unable to generate assessment. Please try again!'
        );
        this.apiloading = false;
      }
    );
  }
  /**
   *  submitting complete assessment
   * @param addinfo //additional information
   */
  async submit2(addinfo: any) {
    addinfo = addinfo?.trim();
    this.apiloading = true;
    window.scroll(0, 0);
    let res = this.getQues.generateRating(this.selectedcat, addinfo);
    this.router.navigate(['report']);
  }

  showPreview(selectedIndex): void {
    this.previewCategory = this.categoryList[selectedIndex].category_name;
  }
  assessmentForm() {
    this.x = 0;
    this.isAddInfo = false;
  }
}
