<form [formGroup]="formname">
  <div *ngFor="let q of ques; let i = index; let last = last">
    <div
      id="subhead"
      class="card-block display-flex shadow bg"
      aria-required="true"
      *ngIf="qno == i"
    >
      <b class="subhead">
        <b>{{ subhead }}</b>
      </b>
      <div class="qnodiv">{{ qno + 1 }} of {{ lastno + 1 }}</div>
      <div>
        <h5 class="question">
          <b>Q. {{ q.question }}&nbsp;</b>
        </h5>
      </div>
      <div>
        <div
          class="form-check"
          *ngFor="let option of q.options"
          [class.selected]="
            this.formname.controls[q.question_key].value != null &&
            (this.formname.controls[q.question_key].value == option.value ||
              this.formname.controls[q.question_key].value.includes(
                option.value
              ) ||
              storedobj[q.question_key].answer_key.includes(option.index))
          "
        >
          <div
            *ngIf="
              this.formname.controls[q.question_key].value == null &&
              q.type == 'radio'
            "
            (click)="
              updateradio(
                q.question_key,
                option.value,
                q.question,
                option.index,
                option.tag
              )
            "
          >
            <input
              class="form-check-input"
              id="{{ q.question_key }}"
              type="{{ q.type }}"
              [value]="option.value"
              name="{{ q.question_key }}"
            />
            <span>{{ option.value }}</span>
          </div>
          <div
            *ngIf="
              this.formname.controls[q.question_key].value == null &&
              q.type == 'checkbox'
            "
            (click)="
              updatecheckbox(
                q.question_key,
                option.value,
                q.question,
                option.tag,
                option.index
              )
            "
          >
            <input
              class="form-check-input"
              id="{{ q.question_key }}"
              type="{{ q.type }}"
              [value]="option.value"
              name="{{ q.question_key }}"
            />
            <span>{{ option.value }}</span>
          </div>
          <div
            *ngIf="
              this.formname.controls[q.question_key].value != null &&
              q.type == 'checkbox'
            "
            (click)="
              updatecheckbox(
                q.question_key,
                option.value,
                q.question,
                option.tag,
                option.index
              )
            "
          >
            <input
              class="form-check-input"
              id="{{ q.question_key }}"
              type="{{ q.type }}"
              [checked]="
                this.formname.controls[q.question_key].value.includes(
                  option.value
                ) || storedobj[q.question_key].answer_key.includes(option.index)
              "
              [value]="option.value"
              name="{{ q.question_key }}"
            />
            <span>{{ option.value }}</span>
          </div>
          <div
            *ngIf="
              this.formname.controls[q.question_key].value != null &&
              q.type == 'radio'
            "
            (click)="
              updateradio(
                q.question_key,
                option.value,
                q.question,
                option.index,
                option.tag
              )
            "
          >
            <input
              class="form-check-input"
              id="{{ q.question_key }}"
              type="{{ q.type }}"
              [checked]="
                this.formname.controls[q.question_key].value == option.value
              "
              [value]="option.value"
              name="{{ q.question_key }}"
            />
            <span>{{ option.value }}</span>
          </div>
        </div>
        <input
          *ngIf="other && current_que == q.question"
          [value]="
            storedobj[q.question_key] &&
            storedobj[q.question_key].tag &&
            storedobj[q.question_key].tag.includes('nan') &&
            this.formname.controls[q.question_key].value &&
            this.formname.controls[q.question_key].value[0] != null
              ? this.formname.controls[q.question_key].value[0]
              : null
          "
          (input)="updateValue($event, q.question_key, q.question)"
          type="text"
          name="{{ q.question_key }}"
          [ngClass]="
            this.formname.controls[q.question_key].value == null ||
            this.formname.controls[q.question_key].value[0] == null
              ? 'is-invalid'
              : ''
          "
          class="form-control text-field placeholder"
          pattern="\S(.*\S)?"
          maxlength="100"
          minlength="2"
          required
          autofocus
          placeholder="* Please provide details"
        />
      </div>
      <div class="nav-buttons">
        <button
          [disabled]="othersText"
          (click)="nextq()"
          *ngIf="qno != lastno"
          class="next"
        >
          Next
          <img class="checkbox-svg" src="../../assets/images/right-arrow.svg" />
        </button>
        <button
          [disabled]="othersText"
          (click)="nextCat()"
          *ngIf="qno == lastno"
          class="next"
        >
          Next
          <img class="checkbox-svg" src="../../assets/images/x-square.png" />
        </button>
        &nbsp;
        <button (click)="prevq()" *ngIf="qno != 0" class="prev">
          <img class="checkbox-svg" src="../../assets/images/left-arrow.svg" />
          Previous
        </button>
        <button
          (click)="stepBackSelect()"
          *ngIf="x == 0 && qno == 0"
          class="prev"
        >
          <img class="checkbox-svg" src="../../assets/images/left-arrow.svg" />
          Select Assessment
        </button>
      </div>
    </div>
  </div>
</form>
