import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetQuestionsService {
  res = {};
  obj = {};
  catResponse = {};
  result: any;
  catList: any;
  email: any;
  attempted = {};
  category_id: string;
  selectedCatName: string;
  selectedCatId;
  allSubCat: any;
  currentSubCat: any;
  addinfo: any;
  nextSubCat: any;
  comparedSubCat = {};
  addrecom: any;
  assessmentID: any;
  allCategories: any;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  initial() {
    this.res = {};
    this.obj = {};
    this.catResponse = {};
    this.attempted = {};
    this.currentSubCat = 0;
    this.nextSubCat = 0;
    this.allSubCat = null;
    this.comparedSubCat = {};
  }
  //API call for ques
  async getQues(category: string, id) {
    this.category_id = id;
    return this.http.post(environment.GET_QUES, {
      category_name: category,
      category_id: id,
    });
  }
  setItem(name: string, item: string): void {
    localStorage.setItem(name, item);
  }
  // get user email from local storage
  getItem(name: string): string {
    return localStorage.getItem(name);
  }
  getUser() {
    return this.getItem('email');
  }
  /**
   * Storing User choice in assessment ques
   * @param key //unique key to store object
   * @param obj //object to store
   * @returns
   */
  storeobj(key: string, obj: any) {
    if (key == 'empty') {
      this.res = {};
    } else {
      if (key == 'editor') {
        this.res = obj;
      } else {
        this.res[key] = obj;
      }
    }
    return this.res;
  }

  storeCat(key: string, obj: any) {
    this.catResponse[key] = obj;
    return this.catResponse;
  }
  /**
   *
   * API call for generating report after submitting assessment
   * @param addinfo //additional information
   * @param category //Selected category
   * @param graphUrls
   * @returns
   */
  submitres(addinfo, category, sbcat, rating, graphUrls: IGraphUrls = null) {
    const response = Object.values(this.res);
    this.obj = {
      assessment: response,
      additionalDetails: this.returnAddinfo(),
      email: this.getUser(),
      category,
      userRole: this.getItem('role'),
      isAssessment: true,
      submittedAssessment: this.res,
      name: this.getItem('name'),
      assessmentRadarGraph: graphUrls ? graphUrls.radarGraphUrl : null,
      assessmentBarGraph: graphUrls ? graphUrls.barGraphUrl : null,
      category_id: this.category_id ? this.category_id : null,
      subCategories: this.allCategories,
      rating: rating,
    };
    //  console.log(this.obj);
    return this.http.post(environment.REPORT_URL, this.obj);
  }
  /**
   *
   * API call for generating report after submitting assessment
   * @param addrecom //additional recommendation
   * @param addinfo //additional information
   * @param category //Selected category
   * @param graphUrls
   * @returns
   */
  submitrecom(
    addrecom,
    addinfo,
    category,
    allCat,
    rating,
    graphUrls: IGraphUrls = null
  ) {
    const response = Object.values(this.res);
    this.obj = {
      assessment: response,
      additionalDetails: this.returnAddinfo(),
      isAssessment: false,
      additionalRecommendation: this.addrecom,
      email: this.getItem('user_email'),
      userRole: this.getItem('role'),
      category: category,
      name: this.getItem('name'),
      userName: this.getItem('user_name'),
      assessmentId: this.assessmentID,
      submittedAssessment: this.res,
      categoryRecommendation: this.catResponse,
      category_id: this.category_id ? this.category_id : null,
      assessmentRadarGraph: graphUrls ? graphUrls.radarGraphUrl : null,
      assessmentBarGraph: graphUrls ? graphUrls.barGraphUrl : null,
      editorEmail: this.getItem('email'),
      subCategories: this.allCategories,
      rating: rating,
    };
    // console.log(this.obj);
    return this.http.post(environment.REPORT_URL, this.obj);
  }
  storeAllcategories(all) {
    this.allCategories = all;
  }
  returnobj() {
    return this.res;
  }
  returncat() {
    return this.catResponse;
  }
  //API call for category
  getCat() {
    return this.http.get(environment.GET_CAT);
  }
  /**
   * removing unattempted question
   * @param key Question key
   * @param subhead subcategory
   */
  popobj(key, subhead) {
    delete this.res[key];
    const index = this.attempted[subhead].attempted.indexOf(key);
    if (index > -1) {
      this.attempted[subhead].attempted.splice(index, 1); // 2nd parameter means remove one item only
    }
    if (this.attempted[subhead].attempted.length == 0) {
      delete this.attempted[subhead];
    }
  }

  /**
   *email reprt to user email
   * @param reportlink // S3 link for report
   * @param assessment_user //email of user who has taken assessment
   */
  emailreport(reportlink, assessment_user, isEditor) {
    if (isEditor) {
      return this.http.post(environment.EMAIL_URL, {
        reportUrl: reportlink,
        email: assessment_user,
        role: 'editor',
        userName: this.getItem('user_name'),
      });
    }
    return this.http.post(environment.EMAIL_URL, {
      reportUrl: reportlink,
      email: assessment_user,
      name: this.getItem('name'),
    });
  }
  /**
   * API call to get draft assessment response obj
   * @param category //category of selected draft assessment
   * @param assessmentID //ID of selected draft assessment
   * @param category_id //category ID of selected draft assessment
   */
  getAssessment(category, assessmentID, category_id) {
    this.category_id = category_id;
    this.assessmentID - assessmentID;
    return this.http.post(environment.GET_ASSESSMENT, {
      category_name: category,
      category_id: category_id,
      assessment_id: assessmentID,
    });
  }
  /**
   * API call to get all draft assessment
   * @param email //editor's email ID
   */
  getDraft(email) {
    return this.http.post(environment.DRAFT_ASSESSMENT, {
      email: email,
    });
  }

  storeInitalCat(catList: any, name, id) {
    this.catList = catList;
    this.selectedCatId = id;
    this.selectedCatName = name;
  }
  returnInitialCat() {
    return [this.catList, this.selectedCatName, this.selectedCatId];
  }

  storeAttempted(subcat, qkey, tno) {
    if (this.attempted[subcat]) {
      if (!this.attempted[subcat].attempted.includes(qkey)) {
        this.attempted[subcat].attempted.push(qkey);
      }
    } else {
      this.attempted[subcat] = {
        attempted: [qkey],
        total: tno,
      };
    }
  }

  returnAttemptedObj() {
    return this.attempted;
  }

  storeCurrentSubCat(allSubCat, current) {
    this.allSubCat = allSubCat;
    this.currentSubCat = current;
  }
  returnCurrentSubCat() {
    return [this.allSubCat, this.currentSubCat, this.selectedCatName];
  }

  storeNextSubCat(selected) {
    this.nextSubCat = selected;
  }

  returnNextSubCat() {
    if (this.nextSubCat) {
      return this.allSubCat.indexOf(this.nextSubCat);
    } else {
      return 0;
    }
  }

  compareAttempted() {
    if (this.allSubCat) {
      this.allSubCat.forEach((ele) => {
        if (this.attempted[ele]) {
          if (
            this.attempted[ele].attempted.length == this.attempted[ele].total
          ) {
            this.comparedSubCat[ele] = '#daebd5';
          } else {
            this.comparedSubCat[ele] = '#fffbe1';
          }
        } else {
          this.comparedSubCat[ele] = '#ffcece';
        }
      });
    } else {
      return {};
    }
    return this.comparedSubCat;
  }

  generateRating(selectedCat, addinfo) {
    const response = Object.values(this.res);
    this.addinfo = addinfo;
    this.obj = {
      assessment: response,
      email: this.getUser(),
      selectedCat,
      userRole: this.getItem('role'),
      isAssessment: true,
      submittedAssessment: this.res,
      name: this.getItem('name'),
      category_id: this.category_id ? this.category_id : null,
      subCategories: this.allCategories,
    };
    // console.log(this.obj);
  }

  generateRatingRec(selectedCat, addinfo, addrecom, assessmentid) {
    const response = Object.values(this.res);
    this.addrecom = addrecom;
    this.addinfo = addinfo;
    this.assessmentID = assessmentid;
    this.obj = {
      assessment: response,
      selectedCat,
      userRole: this.getItem('role'),
      submittedAssessment: this.res,
      name: this.getItem('user_name'),
      UserName: this.getItem('user_name'),
      category_id: this.category_id ? this.category_id : null,
      subCategories: this.allCategories,
      assessmentId: this.assessmentID,
      additionalDetails: this.returnAddinfo(),
      isAssessment: false,
      additionalRecommendation: this.addrecom,
      email: this.getItem('user_email'),
      userName: this.getItem('user_name'),
      categoryRecommendation: this.catResponse,
      editorEmail: this.getItem('email'),
    };
    // console.log(this.obj);
  }

  fetchRating() {
    // console.log(this.obj);
    return this.http.post(environment.GENERATE_RATING, this.obj);
  }

  returnAddinfo() {
    if (this.addinfo.length > 0) {
      return this.addinfo;
    } else return '';
  }
}

export interface IGraphUrls {
  radarGraphUrl: string;
  barGraphUrl: string;
}
