<div class="main-div">
  <div class="bg-color">
    <div class="container">
      <div class="sb-nav-fixed">
        <app-header></app-header>
      </div>
      <h1 class="heading">
        <b>Self-Service Cloud Assessment</b>
      </h1>
      <div class="welcome-message2">
        Evaluate your readiness to embrace the cloud, identify areas for
        improvement, and create a robust roadmap for cloud enablement.
      </div>
      <!--step1-->
      <div class="question-card">
        <div
          *ngIf="apiloading && !isAddInfo"
          class="d-flex justify-content-center"
        >
          <div class="" role="status">
            <img class="square-loader" src="../../assets/images/loader.svg" />
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngIf="!apiloading && !isAddInfo">
          <p class="selectedcat">
            <b>{{ selectedcat }}</b>
          </p>
          <div class="stepper">
            <div
              class="stepper-outer"
              *ngFor="let s of stepperlist; index as i"
            >
              <!-- partially attempted -->
              <p
                *ngIf="comparedAttempted[cat[i]] == '#fffbe1'"
                class="circle category-number"
                style="
                  color: #ffffff;
                  background: #6100ff;
                  box-shadow: 0px 3px 6px #00000029;
                  border: 1px solid #ffffff;
                "
              >
                {{ i + 1 }}
              </p>
              <!-- unattempted -->
              <p
                *ngIf="comparedAttempted[cat[i]] == '#ffcece'"
                class="circle category-number"
                style="
                  background-color: #ffffff;
                  box-shadow: 0px 3px 6px #00000029;
                  color: #6100ff;
                  border-color: #6100ff;
                "
              >
                {{ i + 1 }}
              </p>
              <!-- complete -->
              <p
                *ngIf="comparedAttempted[cat[i]] == '#daebd5'"
                class="circle category-number"
                style="
                  color: #ffffff;
                  background: #6100ff;
                  box-shadow: 0px 3px 6px #00000029;
                  border: 1px solid #ffffff;
                "
              >
                <!-- {{i+1}} -->
                <img class="done-tick" src="../../assets/images/tick.svg" />
              </p>
              <div class="question-stepper" *ngIf="i == x">
                <div class="connect-div" *ngFor="let sub of s; index as j">
                  <hr class="lines" />
                  <p
                    class="question-number"
                    *ngIf="
                      attempted[cat[x]]?.attempted.includes(sub.question_key)
                    "
                    class="ques attempted"
                  >
                    <!-- {{j+1}} -->
                  </p>
                  <p
                    class="question-number"
                    *ngIf="
                      !attempted[cat[x]]?.attempted.includes(sub.question_key)
                    "
                    [ngClass]="
                      j == qno ||
                      attempted[cat[x]]?.attempted.includes(sub.question_key)
                        ? 'active-ques ques'
                        : 'disable-ques ques'
                    "
                  >
                    <!-- {{j+1}} -->
                  </p>
                </div>
                <hr class="lines" />
                <!-- <div style="width: 10px;" *ngIf="i==x"></div> -->
              </div>
            </div>
            <p class="circle category-number" style="background-color: #f3f3f3">
              <img src="../../assets/images/thumbs-up.svg" class="thumb-img" />
            </p>
          </div>
          <p class="help-text">
            <b>Note - </b>Please answer the following multi-choice questions and
            select more than one answer wherever applicable. In case you are
            unsure of the answer to a particular question, please skip it and
            proceed to the next.
          </p>
          <div *ngFor="let s of stepperlist; index as i">
            <div *ngIf="i == x">
              <app-question
                [formname]="cat[x]"
                [userfrm]="userForm"
                [subhead]="cat[x]"
                [ques]="s"
                (formvalid)="valid($event)"
                (compform)="strfrm($event)"
                (queno)="queno($event)"
                (lno)="lno($event)"
              >
              </app-question>
            </div>
          </div>
          <br />
          <!-- <div style="margin-left: -6%">
        &nbsp;
        <button
          (click)="stepBackSelect()"
          *ngIf="x == 0 && qno == 0"
          class="prev"
        >
          Select Assessment
        </button>
        &nbsp;
      </div> -->
          <!-- <div class="next-section">
        <button (click)="nextq()" *ngIf="qno == lastno" class="next">
          Next Category
        </button>
      </div> -->
        </div>
      </div>
      <!--step2-->
      <div *ngIf="isAddInfo">
        <p class="selectedcat">
          <b>{{ selectedcat }}</b>
        </p>
        <div *ngIf="apiloading" class="d-flex justify-content-center">
          <div class="" role="status">
            <img class="square-loader" src="../../assets/images/loader.svg" />
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="stepper" *ngIf="!apiloading">
          <div class="stepper-outer" *ngFor="let s of stepperlist; index as i">
            <!-- partially attempted -->
            <p
              *ngIf="comparedAttempted[cat[i]] == '#fffbe1'"
              class="circle category-number"
              style="
                color: #ffffff;
                background: #6100ff;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
              "
            >
              {{ i + 1 }}
            </p>
            <!-- unattempted -->
            <p
              *ngIf="comparedAttempted[cat[i]] == '#ffcece'"
              class="circle category-number"
              style="
                background-color: #ffffff;
                color: #6100ff;
                box-shadow: 0px 3px 6px #00000029;
                border-color: #6100ff;
              "
            >
              <!-- {{i+1}} -->
              <img
                class="done-tick-assess"
                src="../../assets/images/exclaim.svg"
              />
            </p>
            <!-- complete -->
            <p
              *ngIf="comparedAttempted[cat[i]] == '#daebd5'"
              class="circle category-number"
              style="
                color: #ffffff;
                background: #6100ff;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
              "
            >
              <!-- {{i+1}} -->
              <img
                class="done-tick-assess"
                src="../../assets/images/tick.svg"
              />
            </p>
          </div>
          <p
            class="circle category-number"
            style="
              background: #6100ff;
              box-shadow: 0px 3px 6px #00000029;
              border: 1px solid #ffffff;
            "
          >
            <img
              src="../../assets/images/thumbs-up-assess.svg"
              class="thumb-img"
            />
          </p>
        </div>
        <div class="card-block shadow">
          <b class="subhead">Additional information (optional)</b>
          <p class="addText">
            Please mention any other relevant details about your existing
            infrastructure or technology stack that you would like to share with
            us.
          </p>
          <br />
          <div class="form-group">
            <textarea
              maxlength="1000"
              class="form-control addTextBox"
              id="addinformation"
              #addinfo
              rows="5"
              >{{ additionalInfo }}</textarea
            >
          </div>
          <p class="addText">
            <b>Disclaimer</b> - The additional Information captured here will
            not have an impact on your assessment report. It is only captured to
            gain a deeper understanding of your requirements.
          </p>
          <br />
          <div class="button-section">
            <!-- <div style="margin-left: -6%">
          &nbsp;
          <button class="prev" (click)="assessmentForm()"> <img class="checkbox-svg"
              src="../../assets/images/left-arrow.svg" />Assessment Form</button>
        </div> -->
            <div class="next-section">
              &nbsp;
              <button
                [disabled]="apiloading"
                type="submit"
                (click)="submit2(addinfo.value)"
                class="next"
              >
                <span class="" aria-hidden="true"
                  >Submit Assessment
                  <img
                    class="checkbox-svg"
                    src="../../assets/images/x-square.png"
                /></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--step3-->
    </div>
  </div>
</div>
