<div class="bg-color">
  <div class="outer-div">
    <div class="sb-nav-fixed">
      <app-header></app-header>
    </div>
    <div class="intro-div">
      <div class="intro-image">
        <img class="cylinder-image" src="../../assets/images/MaskGroup.png" />
      </div>
      <div class="welcome-text">
        <div>
          <p class="welcome-message">
            Welcome to Impetus’<br />Self-Service Cloud Assessment!
          </p>
          <p class="welcome-subtext">
            Whether you’re looking to start your cloud journey afresh, migrate
            existing workloads, or optimize a cloud deployment, Impetus’
            self-service cloud assessment provides expert insights based on your
            specific requirements, goals, and priorities. Get customized
            recommendations to help reduce cost, optimize resource utilization,
            enhance security, and build a mature foundation for life in the
            cloud.
          </p>
        </div>
        <div class="user-form">
          <p *ngIf="!iseditor" class="form-head">Help us with your details</p>
          <div class="outer-form" *ngIf="!iseditor">
            <form #loginForm="ngForm">
              <div class="form-content">
                <div class="row1">
                  <div class="form-label-group label-group">
                    <!-- <label class="bold_labels" for="firstName"
              >First Name</label
            > -->
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                      [(ngModel)]="name"
                      pattern="^[A-Za-z']+$"
                      required
                      #firstNameField="ngModel"
                      name="name"
                      id="firstName"
                      class="form-control label1"
                      required
                      maxlength="50"
                      minlength="3"
                      [ngClass]="{
                        'is-invalid':
                          firstNameField.dirty && firstNameField.invalid
                      }"
                      placeholder="Enter First Name"
                    />
                    <div
                      *ngIf="firstNameField.invalid && firstNameField.dirty"
                      class="error-text"
                    >
                      <div *ngIf="firstNameField.errors.required">
                        First Name is required
                      </div>
                      <div *ngIf="firstNameField.errors.pattern">
                        Invalid First Name
                      </div>
                      <span *ngIf="firstNameField.errors.minlength">
                        Name should have at least 3 characters
                      </span>
                    </div>
                  </div>

                  <div class="form-label-group label-group">
                    <!-- <label class="bold_labels" for="lastName"
              >Last Name</label
            > -->
                    <input
                      type="text"
                      oninput="this.value = this.value.replace(/[^A-Za-z0-9-,.;'&/.() ]|^ /g,'')"
                      [(ngModel)]="lastname"
                      pattern="^[A-Za-z ']+$"
                      required
                      #lastNameField="ngModel"
                      name="lastname"
                      id="lastName"
                      class="form-control label1"
                      required
                      maxlength="50"
                      minlength="3"
                      [ngClass]="{
                        'is-invalid':
                          lastNameField.dirty && lastNameField.invalid
                      }"
                      placeholder="Enter Last Name"
                    />
                    <div
                      *ngIf="lastNameField.invalid && lastNameField.dirty"
                      class="error-text"
                    >
                      <div *ngIf="lastNameField.errors.required">
                        Last Name is required
                      </div>
                      <div *ngIf="lastNameField.errors.pattern">
                        Invalid Last Name
                      </div>
                      <span *ngIf="lastNameField.errors.minlength">
                        Name should have at least 3 characters
                      </span>
                    </div>
                  </div>
                </div>
                <div class="form-label-group label-group">
                  <!-- <label class="bold_labels" for="emailField"
              >Enter Your Business Email</label
            > -->
                  <input
                    type="text"
                    [(ngModel)]="email"
                    pattern="^([a-zA-Z0-9]+[a-zA-Z0-9_\-\.]+)@(?!(gmail|yahoo|hotmail|outlook|yandex|proton|zoho|live|msn|me|rediffmail|tutanota|ymail|iCloud|icloud|10minute))([a-zA-Z0-9]+((\.+[a-zA-Z0-9]{2,4}\.[a-zA-Z0-9]{2,4})|(\.+[a-zA-Z0-9]{2,4})))$"
                    required
                    #emailField="ngModel"
                    name="email"
                    id="inputEmail"
                    class="form-control label1 label2"
                    [ngClass]="{
                      'is-invalid': emailField.dirty && emailField.invalid
                    }"
                    placeholder="Enter Business Email"
                  />

                  <div
                    *ngIf="emailField.invalid && emailField.dirty"
                    class="error-text"
                  >
                    <div *ngIf="emailField.errors.required">
                      Email is required
                    </div>
                    <div *ngIf="emailField.errors.pattern">
                      Invalid email pattern
                    </div>
                  </div>
                </div>
                <p class="Privacy">
                  By using this portal, you agree to the our
                  <b>Terms Of Services</b> and
                  <b>Privacy Policy</b>
                </p>
                <button
                  (click)="verifyUser()"
                  [disabled]="
                    emailField.invalid ||
                    lastNameField.invalid ||
                    firstNameField.invalid
                  "
                  class="btn btn-primary marginBtn next-btn"
                  type="submit"
                >
                  Next
                  <img
                    class="checkbox-svg"
                    src="../../assets/images/x-square.png"
                  />
                  <span
                    *ngIf="verifyuser"
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </form>
          </div>
          <div class="recommendation-options" *ngIf="iseditor">
            <button
              class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
              type="submit"
              (click)="recommendation()"
            >
              Give Recommendation
            </button>
            <button
              (click)="takeassessment()"
              class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
              type="submit"
            >
              Take Assessment
            </button>
          </div>
        </div>
      </div>
    </div>
    <app-preview-section></app-preview-section>
  </div>
</div>
