<div class="reportpage">
  <div class="score-container">
    <div class="header">
      <app-header (click)="homeRedirect()"></app-header>
    </div>
    <span class="mailDate" *ngIf="chartDataUpdated && !apiloading">
      <!-- <p><b>Email: </b>{{ userEmailAddress }}</p> -->
      <p><b>Date: </b>{{ formatedCurrentTime }}</p>
    </span>
    <div class="score">
      <div class="text">
        <div class="inner-text">
          <p>You have taken up {{ selectedcat }} <br /></p>
        </div>
        <div *ngIf="!isReportGenerated" class="report-loader-box">
          <div class="d-flex">
            <div class="" role="status">
              <img class="square-loader" src="../../assets/images/loader.svg" />
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="report-gen">Your report is generating!</div>
        </div>
        <div class="download" *ngIf="isReportGenerated">
          <div class="download-img">
            <img
              class="download-svg"
              src="../../assets/images/graph-report-icon-vector-15801000.png"
            />
          </div>
          <div class="download-text">
            <p>Download and Email your Report</p>
            <span
              class="button report-buttons"
              (click)="email()"
              target="_blank"
              >Email
              <img class="email-svg" src="../../assets/images/email-svg.svg" />
              <span
                style="margin: 0px 0px 0px 2px"
                *ngIf="emailloading"
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span
            ></span>
            <span
              class="button report-buttons"
              (click)="downloadReport()"
              target="_blank"
              >Download
              <img class="down-arrow" src="../../assets/images/down-arrow.svg"
            /></span>
          </div>
        </div>
      </div>
      <div class="charts">
        <div class="square">
          <!-- <span class="mailDate" *ngIf="chartDataUpdated && !apiloading">
            <!- <p><b>Email: </b>{{ userEmailAddress }}</p> ->
            <p><b>Date: </b>{{ formatedCurrentTime }}</p>
          </span> -->
          <div *ngIf="apiloading" class="d-flex justify-content-center">
            <div class="" role="status">
              <img class="square-loader" src="../../assets/images/loader.svg" />
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div id="chart" *ngIf="!apiloading">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [legend]="chartOptions.legend"
              [dataLabels]="chartOptions.dataLabels"
              [colors]="chartOptions.colors"
              [plotOptions]="chartOptions.plotOptions"
              [yaxis]="chartOptions.yaxis"
            >
            </apx-chart>
          </div>
        </div>
        <div class="graph">
          <p class="graph-text">
            No business is cookie-cutter, and we don’t take a one-size-fits-all
            approach to our work
          </p>
          <div *ngIf="apiloading" class="d-flex justify-content-center">
            <div class="" role="status">
              <img class="square-loader" src="../../assets/images/loader.svg" />
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div id="spider" *ngIf="!apiloading">
            <apx-chart
              [series]="SpiderChart.series"
              [chart]="SpiderChart.chart"
              [xaxis]="SpiderChart.xaxis"
              [stroke]="SpiderChart.stroke"
              [colors]="SpiderChart.colors"
              [dataLabels]="SpiderChart.dataLabels"
              [tooltip]="SpiderChart.tooltip"
              [plotOptions]="SpiderChart.plotOptions"
              [markers]="SpiderChart.markers"
              [yaxis]="SpiderChart.yaxis"
              [fill]="SpiderChart.fill"
              [title]="SpiderChart.title"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row-2">
    <div class="recommendation">
      <h1>Recommendation</h1>
      <div *ngIf="apiloading" class="d-flex justify-content-center">
        <div class="" role="status">
          <img class="square-loader" src="../../assets/images/loader.svg" />
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="rating-box" *ngFor="let data of rating">
        <div
          class="circle"
          *ngIf="!apiloading"
          [ngClass]="{
            'circle-color-red': data.rating < '50',
            'circle-color-green': data.rating >= '70' && data.rating < '101',
            'circle-color-orange': data.rating < '70' && data.rating >= '50'
          }"
        >
          {{ data.rating }}%
        </div>
        <div class="text" *ngIf="!apiloading">
          <h4 class="subcat-name">{{ data.subCategory }}</h4>
          <p [innerHTML]="data.recommendation" class="subcat-recommend"></p>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="partners">
    <div class="partner-tax">
      <h1>Our Partners</h1>
      <p>
        Our deep-rooted partnerships with cloud leaders help us bring <br />
        any efficiency, optimization, or modernization into your reach
      </p>
    </div>
    <div class="cards-row">
      <div class="card">
        <img class="cloud-card-aws" src="../../assets/images/aws-partners.svg" />
        <p class="card-head">Amazon Web Services</p>
        <p class="sub-text">
          Whether you’re looking to innovate, optimize,
          rearchitect, and scale on AWS, or if you’re 
          curious about its possibilities, count on us. 
          With expertise in successfully marrying AWS with 
          our clients’ digital transformation goals, we’ll help you make
          the right decision for your business—faster.
        </p>
   
        <a
          class="button see-how aws-button"
          href="https://www.impetus.com/partnerships/success-with-aws/"
          target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>

      <div class="card">
        <img class="cloud-card" src="../../assets/images/azure-partners.svg" />
        <p class="card-head">Microsoft</p>
        <p class="sub-text">
          Make the right business decisions by aligning
          with your digital transformation goals. As
          a CAF-approved Gold Partner, we can help you
          leverage Azure to achieve more across
          business applications, data analytics,
          and cloud transformation.
        </p>
        <a
          class="button see-how"
          href="https://www.impetus.com/partnerships/success-with-azure/"
          target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>

      <div class="card">
        <img
          class="cloud-card rect-img"
          src="../../assets/images/google-cloud-partners.svg"
        />
        <p class="card-head">Google Cloud</p>
        <p class="sub-text">
          Drive high-impact business innovation by
          accelerating your adoption of Google Cloud Platform (GCP).
          We’ll bring our deep digital technology expertise to modernize
          your applications and help you maximize the benefits of
          GCP.
        </p>
        <a
          class="button see-how"
          href="https://www.impetus.com/partnerships/success-with-gcp/"
          target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>


      <div class="card">
        <img
          class="cloud-card  rect-img"
          src="../../assets/images/databricks-partners.svg"
        />
        <p class="card-head">Databricks</p>
        <p class="sub-text">
          Whether you’re looking to accelerate optimize,
          rearchitect,and scale on Databricks, or automate your
          legacy workloads,count on us. 
          As a preferred partner​, we can help you explore the
          transformation potential in 
          your business with Databricks.
        </p>
        <a
          class="button see-how"
          href="https://www.impetus.com/partnerships/success-with-databricks/"
          target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>

      <div class="card">
        <img
          class="cloud-card"
          src="../../assets/images/snowflake-partners.svg"
        />
        <p class="card-head">Snowflake</p>
        <p class="sub-text">
          If you want to build a data-driven
          future for your business on Snowflake, 
          we’re ready to partner with you to power successful migration
          from any legacy platform.
        </p>
    
        <a
          class="button see-how"
          href="https://www.impetus.com/partnerships/success-with-snowflake/"
          target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>

      <div class="card">
        <img
          class="cloud-card rect-img"
          src="../../assets/images/cloudera-partners.svg"
        />
        <p class="card-head">Cloudera</p>
        <p class="sub-text">
          The solution helped develop an
          end-to-end customer-360 journey, 
          migrated data and ingestion pipeline, and modernized the
          existing lead generation models.
        </p>
        <a class="button see-how" 
        href="https://www.impetus.com/partnerships/"
        target="_blank"
          >See how
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
        /></a>
      </div>
    </div>
  </div> -->

  <div class="about-us">
    <h1>About Us</h1>
    <p>
      Impetus Technologies is a digital engineering company focused on
      delivering expert services and products to help enterprises achieve their
      transformation goals. We solve the analytics, AI, and cloud puzzle,
      enabling businesses to drive unmatched innovation and growth. Impetus is
      proud to partner with such Fortune 100 clients as American Express, Bank
      of America, Capital One, Fannie Mae, McKesson, Toyota, United Airlines,
      Verizon and more. The company is headquartered in Los Gatos, California
      with international offices in India, Australia and Canada.
    </p>
    <a href="https://impetus.com/">https://impetus.com/</a>
  </div>

  <app-footer></app-footer>

  <div class="footer">
    <div class="left">
      <p class="foot-text">Expert Consultation</p>
      <p class="foot-text">
        Reach out to
        <a href="mailto:inquiry@impetus.com"><b>inquiry@impetus.com</b></a>
      </p>
      <p class="copyright">
        © 2022 Impetus Technologies, Inc. All Rights Reserved
      </p>
    </div>
  </div>
</div>
