<div class="outer-box">
  <div class="intro-box">
    <div class="header">
      <div class="nav-logo">
        <img class="logo" src="../../assets/images/impetus_logo_white.svg" />
      </div>
      <div class="nav-links">
        <a class="links" href="https://www.impetus.com/" target="_blank"
          >Home</a
        >
        <a
          class="links"
          href="https://www.impetus.com/partnerships/"
          target="_blank"
          >Partner</a
        >
        <a
          class="links"
          href="https://www.impetus.com/resources/"
          target="_blank"
          >Resources</a
        >
        <a class="links" href="https://www.impetus.com/about/" target="_blank"
          >About</a
        >
        <a
          class="links"
          href="https://www.impetus.com/about/contact/"
          target="_blank"
          >Contact</a
        >
        <!-- <button class="button sign-in" (click)="signIn()">Sign In</button> -->
      </div>

      <div class="ham-menu">
        <input id="toggle" type="checkbox" />
        <label for="toggle" class="hamburger">
          <div class="top-bun"></div>
          <div class="meat"></div>
          <div class="bottom-bun"></div>
        </label>

        <div class="nav">
          <div class="nav-wrapper">
            <nav>
              <a target="_blank" href="https://www.impetus.com/">Home</a><br />
              <a target="_blank" href="https://www.impetus.com/partnerships/"
                >Partner</a
              ><br />
              <a target="_blank" href="https://www.impetus.com/resources/"
                >Resources</a
              ><br />
              <a target="_blank" href="https://www.impetus.com/about/">About</a
              ><br />
              <a target="_blank" href="https://www.impetus.com/about/contact/"
                >Contact</a
              >
            </nav>
          </div>
        </div>
      </div>
    </div>
    <p class="heading1">Self-Service Cloud Assessment</p>
    <!-- <p class="moto">Fast. Simple. Free.</p> -->
    <p class="sub-heading">
      <!-- Evaluate your readiness to embrace the cloud, migrate existing workloads, or optimize a cloud deployment. Get comprehensive, actionable recommendations that help you build a mature foundation for life in the cloud. -->
      Actionable recommendations to power cloud transformation at speed and
      scale
    </p>
    <button class="button take_assessment" (click)="signIn()">
      <!-- Take Assessment for Free! -->
      Start Now
    </button>
  </div>
</div>
<!-- <div class="outer-block1">
  <div class="block1">
    <div class="block-header">
      <div class="end-heading">
        <p class="block1-head1">
          <b>How ready are you for life in the cloud?</b>
        </p>
        <p class="end-subhead">
          Evaluate your readiness to embrace the cloud, migrate existing workloads, or optimize a cloud deployment. 
        </p>
      </div>
    </div>
    <div class="block1-body">
      <div class="col2">
        <p class="block1-subhead"> <b>1.	Cloud migration readiness </b> </p> 
        <p class="block1-subhead-text">Asses your readiness for migrating from an on-prem/private cloud-based legacy system to a public cloud platform</p> 
        <p class="block1-subhead"> <b>2.	Cloud architecture maturity </b> </p> 
        <p class="block1-subhead-text">Evaluate the maturity of your existing cloud architecture to formulate a powerful optimization strategy</p> 
        <p class="block1-subhead"> <b>3.	Cloud enablement readiness </b> </p> 
        <p class="block1-subhead-text">Assess your readiness to set up a new enterprise system on a public cloud platform from scratch</p>  
      </div>
      <div class="col1">
        <button class="case-button">View Sample Report 
          <img
          class="diagonal-arrow"
          src="../../assets/images/diagonal-arrow.svg"
          />
        </button>
      </div>
      </div>
  </div>
</div> -->
<div class="outer-mid-block">
  <div class="animation">
    <img class="semi-circle" src="../../assets/images/semi-circle.svg" />
  </div>
  <div class="mid-block">
    <p class="mid-head1">
      <!-- See how Impetus drives Self-Service Cloud Assessment -->
      Where are you in your digital transformation journey?
    </p>
    <p class="subhead">
      Evaluate your readiness to embrace the cloud, migrate existing workloads,
      or optimize cloud deployment.
    </p>
    <div class="mid-card">
      <div class="landing-card">
        <p class="cat-card-head">Cloud migration readiness</p>
        <p class="cat-mid-sub-text">
          Assess your readiness for migrating from an on-prem/private
          cloud-based legacy system to a public cloud platform
        </p>
      </div>
      <div class="landing-card">
        <p class="cat-card-head">Cloud architecture maturity</p>
        <p class="cat-mid-sub-text">
          Evaluate the maturity of your existing cloud architecture to formulate
          a powerful optimization strategy
        </p>
      </div>
      <div class="landing-card">
        <p class="cat-card-head">Cloud enablement readiness</p>
        <p class="cat-mid-sub-text">
          Assess your readiness to set up a new enterprise system on a public
          cloud platform from scratch
        </p>
      </div>
    </div>
    <div class="col1">
      <button class="case-button" (click)="openUrl('https://kloudjet-assessment-portal-storage-prod.s3.amazonaws.com/reports/pdfs/selfserviceassessment-1660547750171.pdf')">View Sample Report 
        <img
          class="diagonal-arrow"
          src="../../assets/images/diagonal-arrow.svg"
        />
      </button>
    </div>
  </div>
  <!-- <div class="mid-block">
    <p class="mid-head1">
      Your path to a robust cloud roadmap
    </p>
    <p class="subhead">
      We offer different assessments based on your organization’s technical requirements,<br> business goals, and priorities. Here’s a glimpse of how it works.
    </p>
    <div class="mid-card">
      <div class="landing-card">
        <p class="card-head">Discover</p>
        <div class="cloud-card">
          <img class="cloud-img discover-img" src="../../assets/images/discover.svg" />
        </div>
        <p class="sub-text">
          Loreum ipsum dolor sit<br /><b>500+ data feeds</b>
        </p>
      </div>
      <div class="landing-card">
        <p class="card-head">Assess</p>
        <div class="cloud-card">
          <img class="cloud-img" src="../../assets/images/assess.svg" />
        </div>
        <p class="sub-text">
          Loreum ipsum dolor sit<br /><b>500+ data feeds</b>
        </p>
      </div>
      <div class="landing-card">
        <p class="card-head">Recommend</p>
        <div class="cloud-card">
          <img class="recommend-img" src="../../assets/images/recoomnd.svg" />
        </div>
        <p class="sub-text">
          Loreum ipsum dolor sit<br /><b>500+ data feeds</b>
        </p>
      </div>
    </div>
  </div> -->
</div>
<div class="outer-end-block">
  <div class="end-block">
    <div class="end-header">
      <div class="end-heading">
        <p class="end-head1">
          <!-- Benefits of <b>Self-Service Cloud Assessment</b> with Impetus. -->
          <b>Waterfall of business benefits</b>
          <!-- <b>Waterfall of business benefits</b> -->
        </p>
        <p class="end-subhead">
          Get customized, data-driven recommendations that help build a mature
          cloud foundation. Discover proven strategies and best practices for
          your target environment.
        </p>
      </div>
      <div class="animation2">
        <img class="semi-circle2" src="../../assets/images/semi-circle2.svg" />
      </div>
    </div>
    <div class="end-card">
      <div class="row1">
        <div class="landing-card2">
          <p class="card-head2">Devise a robust cloud strategy</p>
          <div class="card2-img">
            <img class="cloud-robust" src="../../assets/images/robust.svg" />
          </div>
          <p class="sub-text2">
            Get comprehensive insights to simplify cloud enablement, modernize
            applications, adapt to changing requirements, and minimize vendor
            lock-in.
          </p>
        </div>
        <!-- <div class="landing-card2">
          <p class="card-head2">Build mature foundation for cloud</p>
          <p class="sub-text2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales pulvinar sic
            tempor. Sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra
            vulputate.
          </p>
        </div> -->
        <div class="landing-card2">
          <p class="card-head2">Optimize resource utilization</p>
          <div class="card2-img">
            <img
              class="cloud-resource"
              src="../../assets/images/resource-util.svg"
            />
          </div>
          <p class="sub-text2">
            Learn how to optimize your clusters, workloads, and infrastructure
            to enhance agility and scalability.
          </p>
        </div>
        <!-- <div class="row2"> -->
        <div class="landing-card2">
          <p class="card-head2">Lower cloud cost</p>
          <div class="card2-img">
            <img
              class="cloud-cost"
              src="../../assets/images/cloud-saving.svg"
            />
          </div>
          <p class="sub-text2">
            Discover how to overcome cloud cost management challenges and
            optimize costs across multiple environments to improve your bottom
            line.
          </p>
        </div>
        <div class="landing-card2">
          <p class="card-head2">Improve security</p>
          <div class="card2-img">
            <img
              class="cloud-security"
              src="../../assets/images/cloud-security.svg"
            />
          </div>
          <p class="sub-text2">
            Proactively ensure best-in-class security and governance practices.
            Learn how you can manage access control, authorization, and policies
            efficiently.
          </p>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
<div class="mid-block case-block">
  <p class="mid-head1">Trusted by Fortune 500 companies</p>
  <!-- <p class="subhead">
    These assessments are a collection of carefully curated questions to help
    evaluate your<br />requirements, business goals, and priorities.
  </p> -->
  <div class="mid-card partner-card">
    <div class="landing-card case-card">
      <div>
        <!-- <div class="logo-icons">
          <img class="snow-img" src="../../assets/images/snowflake-icon-main.svg" />
        </div> -->
        <p class="card-head">Case Study</p>
        <p class="mid-sub-text">
          A future-proof, centralized data warehouse with Snowflake on Azure
        </p>
      </div>
      <div class="case-see-how">
        <button
          class="case-button"
          (click)="
            openUrl(
              'https://www.impetus.com/resources/case-studies/a-future-proof-centralized-data-warehouse-with-snowflake-on-azure/'
            )
          "
        >
          Learn more
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
          />
        </button>
      </div>
    </div>
    <div class="landing-card case-card">
      <div>
        <!-- <div class="logo-icons">
          <img class="data-img" src="../../assets/images/databrick-icon.svg" />
        </div> -->
        <p class="card-head">Case Study</p>
        <p class="mid-sub-text">
          30% performance improvement by converting Netezza and Informatica to
          Azure-Databricks stack
        </p>
      </div>
      <div class="case-see-how">
        <button
          class="case-button"
          (click)="
            openUrl(
              'https://www.impetus.com/resources/case-studies/30-performance-improvement-by-converting-netezza-and-informatica-to-azure-databricks-stack/'
            )
          "
        >
          Learn more
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
          />
        </button>
      </div>
    </div>

    <div class="landing-card case-card">
      <div>
        <!-- <div class="logo-icons">
          <img class="aws-img" src="../../assets/images/aws-partners.svg" />
        </div> -->
        <p class="card-head">Case Study</p>
        <p class="mid-sub-text">
          2x faster application deployment with AWS Kubernetes
        </p>
      </div>
      <div class="case-see-how">
        <button
          class="case-button"
          (click)="
            openUrl(
              'https://www.impetus.com/resources/case-studies/enabled-2x-faster-application-deployment-with-aws-kubernetes/'
            )
          "
        >
          Learn more
          <img
            class="diagonal-arrow"
            src="../../assets/images/diagonal-arrow.svg"
          />
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <div class="outer-end-block">
  <div class="end-block">
    <div class="end-heading">
      <p class="end-head1">
        <b>Deep rooted partnerships with cloud leaders</b>
      </p>
    </div>
    <div class="partner-cards">
      <div class="partner-row1">
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/success-with-aws/">
          <img class="partner-logo" src="../../assets/images/AWS-partner-badge-snippet.PNG">
          </a>
        </div>
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/success-with-azure/">
          <img class="partner-logo" src="../../assets/images/Microsoft-partner-badge-snippet.PNG">
          </a>
        </div>
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/success-with-gcp/">
          <img class="gcp-partner-logo" src="../../assets/images/GCP-partner-badge-snippet.PNG">
          </a>
        </div>
      </div>
      <div class="partner-row2">
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/">
          <img class="partner-logo-rect" src="../../assets/images/cloudera.svg">
          </a>
        </div>
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/success-with-snowflake/">
          <img class="partner-logo-circle" src="../../assets/images/Services-Partner-Premier.png">
          </a>
        </div>
        <div class="partner-box">
          <a target="_blank" href="https://www.impetus.com/partnerships/success-with-databricks/">
          <img class="partner-logo-rect" src="../../assets/images/databricks-partner-badge-snippet.PNG">
          </a>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-footer></app-footer>
<div class="contact-section outer-box">
  <p class="end-head1 contact-head">
    <b>Learn how we can help you achieve your digital transformation goals</b>
  </p>
  <button
    class="button contact-button"
    (click)="openUrl('https://www.impetus.com/about/contact/')"
  >
    Contact Us
  </button>
</div>

<div class="footer">
  <p class="footer-text">
    © 2022 Impetus Technologies, Inc. All Rights Reserved
  </p>
</div>
