<div class="main-div">
  <div class="bg-color">
    <div class="sb-nav-fixed">
      <app-header></app-header>
    </div>
    <div class="container">
      <h1 class="heading">
        <b>Self-Service Cloud Assessment</b>
      </h1>
      <div class="welcome-message2">
        Evaluate your readiness to embrace the cloud, identify areas for
        improvement, and create a robust roadmap for cloud enablement.
      </div>
      <div *ngIf="apiloading" class="d-flex justify-content-center">
        <div class="" role="status">
          <img class="square-loader" src="../../assets/images/loader.svg" />
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div *ngIf="!apiloading">
        <div class="container inner-container">
          <div class="row category_item">
            <p style="text-align: center; left: -15px">
              <b class="select-one">
                To get started, select one of the following options in line with
                your specific infrastructure setup and business needs.</b
              >
            </p>
            <div *ngFor="let Category of categoryList; let i = index">
              <div
                *ngIf="Category.category_active"
                class="category"
                [class.selected]="selected && selectedId == Category.id"
              >
                <div
                  (click)="
                    selectAssessment(Category.category_name, Category.id)
                  "
                >
                  <p class="category-name">
                    <b>{{ Category.category_name }}</b>
                  </p>
                  <p class="category-description">
                    {{ Category.category_desc }}
                  </p>
                </div>
              </div>
              <div
                *ngIf="!Category.category_active"
                class="category"
                style="background-color: rgb(241, 240, 240)"
              >
                <div>
                  <b>{{ Category.category_name }}</b
                  ><br />
                  {{ Category.category_desc }}
                </div>
              </div>
            </div>
          </div>
          <div class="row btn-div">
            <div>
              <button
                (click)="next()"
                [disabled]="!selectedId"
                class="btn"
                type="submit"
              >
                Next
                <img
                  class="checkbox-svg"
                  src="../../assets/images/x-square.png"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
