export class QuestionBase<T> {
  question: string;
  question_key: string;
  value: string;
  type: string;
  options: { key: string; value: string }[];

  constructor(
    opt: {
      value?: string;
      question?: string;
      question_key?: string;
      type?: string;
      options?: { key: string; value: string }[];
    } = {}
  ) {
    this.value = opt.value;
    this.question_key = opt.question_key || '';
    this.question = opt.question || '';
    this.type = opt.type || '';
    this.options = opt.options || [];
  }
}
