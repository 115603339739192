import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  status: boolean = false;

  constructor() {}

  isLoggedIn(): boolean {
    let value = localStorage.getItem('loggedIn');
    this.status = JSON.parse(value);
    return this.status;
  }

  setFalse(page?: string) {
    if (page) {
      localStorage.setItem('page', 'report');
      localStorage.setItem('loggedIn', 'false');
    } else {
      localStorage.setItem('loggedIn', 'false');
    }
  }
}
