import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit(): void {
    localStorage.removeItem('page');
    this.auth.setFalse();
  }

  signIn() {
    this.router.navigate(['user-info']);
  }
  openUrl(url: string) {
    window.open(url, '_blank');
  }
}
