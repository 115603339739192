<meta name="viewport" content="width=device-width, initial-scale=1" />
<!-- <nav class="sb-topnav navbar navbar-expand bg-dark">
  <div class="logo">
    <img
      (click)="back()"
      class="logo"
      src="../../assets/images/impetus_logo_white.svg"
    />
  </div>
  <div style="width: 65%">
  </div>
  <div *ngIf="!loggedin">
    <div class="nav-dropdown">
      <div class="dropdown">
        <button class="dropbtn dropbtn-color">Resources</button>
        <div class="dropdown-content">
          <a href="https://www.impetus.com/resources/category/blog/"
            >Blog Posts</a
          >
          <a
            href="https://www.impetus.com/resources/?cat=3&technology=&industry="
            >Case Studies</a
          >
          <a
            href="https://www.impetus.com/resources/?cat=4&technology=&industry="
            >Podcasts</a
          >
          <a
            href="https://www.impetus.com/resources/?cat=5&technology=&industry="
            >Solution Briefs</a
          >
          <a
            href="https://www.impetus.com/resources/?cat=6&technology=&industry="
            >Webinars</a
          >
          <a
            href="https://www.impetus.com/resources/?cat=7&technology=&industry="
            >Whitepapers</a
          >
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn dropbtn-color">About Us</button>
        <div class="dropdown-content">
          <a href="https://www.impetus.com/industries/">Industries</a>
          <a href="https://www.impetus.com/partnerships/">Partnerships</a>
          <a href="https://www.impetus.com/services/">Services</a>
          <a href="https://www.impetus.com/about/">The Organization</a>
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn">
          <a
            class="nav-link dropbtn-color"
            href="https://www.impetus.com/about/contact/"
            >Contact Us</a
          >
        </button>
      </div>
    </div>
  </div>
</nav> -->
<div class="header">
  <img class="black-logo" src="../../assets/images/impetus_logo.svg" />
</div>
