import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserInfoComponent } from './user-info/user-info.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { QuestionComponent } from './assessment/question/question.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { NgApexchartsModule } from 'ng-apexcharts';
import * as ApexCharts from 'apexcharts';
import { CustomAssessmentComponent } from './custom-assessment/custom-assessment.component';
import { CustomQuestionsComponent } from './custom-assessment/custom-questions/custom-questions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreviewSectionComponent } from './preview-section/preview-section.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReportComponent } from './report/report.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { SelectAssessmentComponent } from './select-assessment/select-assessment.component';
import { SelectSubcategoryComponent } from './select-subcategory/select-subcategory.component';
import { CustomReportComponent } from './custom-report/custom-report.component';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';
// import { Ng2BootstrapModule } from 'ng-bootstrap';
const routes: Routes = [
  { path: 'user-info', component: UserInfoComponent },
  {
    path: 'assessment',
    component: AssessmentComponent,
    canActivate: [AuthGuard],
  },
  { path: 'app', component: AppComponent },
  { path: '', component: LandingPageComponent },
  {
    path: 'select-assessment',
    component: SelectAssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'custom-recommendation',
    component: CustomAssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'select-subcategory',
    component: SelectSubcategoryComponent,
    canActivate: [AuthGuard],
  },
  { path: 'report', component: ReportComponent, canActivate: [AuthGuard] },
];
const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 50,
    },
    vertical: {
      position: 'top',
      distance: 30,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UserInfoComponent,
    LandingPageComponent,
    AssessmentComponent,
    QuestionComponent,
    FooterComponent,
    CustomAssessmentComponent,
    CustomQuestionsComponent,
    PreviewSectionComponent,
    ReportComponent,
    SelectAssessmentComponent,
    SelectSubcategoryComponent,
    CustomReportComponent,
  ],
  imports: [
    BrowserModule,
    NotifierModule.withConfig(notifierDefaultOptions),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgApexchartsModule,
    RouterModule.forRoot(routes),
    NgbModule,
  ],
  providers: [AuthService],
  exports: [MatDialogModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
