import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  firebaseConfig = {
    apiKey: environment.apiKey,
    authDomain: environment.authDomain,
    projectId: environment.projectId,
    storageBucket: environment.storageBucket,
    messagingSenderId: environment.messagingSenderId,
    appId: environment.appId,
    measurementId: environment.measurementId,
  };
  app = initializeApp(this.firebaseConfig);
  analytics = getAnalytics(this.app);

  constructor(private router: Router) {}
  ngOnInit(): void {
    this.router.navigate(['']);
    // this.router.navigate(['user-info']);
    // this.router.navigate(['custom-recommendation']);
    // this.router.navigate(['select-assessment']);
    // this.router.navigate(['report']);
  }
}
