// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  REPORT_URL:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/ssp-report',
  GENERATE_RATING:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/generate-rating',
  // OTP_URL:"https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/otp",
  // VERIFY_OTP:"https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/verify-otp",
  VERIFY_USER:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/verifyUserRole',
  GET_QUES:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/assessment-questions',
  GET_CAT:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/assessment-type',
  EMAIL_URL:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/mail-report',
  GET_ASSESSMENT:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/assessment/review',
  DRAFT_ASSESSMENT:
    'https://3yzlbftnd5.execute-api.us-east-1.amazonaws.com/dev/v1/draft/assessment',
  ACCESS_KEY: 'AKIAXMPI5V5RE45C7QDP',
  SECRET_KEY: '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',
  BUCKET: 'kloudjet-assessment-portal-storage-dev',

  //analytics
  apiKey: 'AIzaSyD4frlpn6jRB7b2qiomK4WeUIUmlbbTIzA',
  authDomain: 'impetus-assessportal-dev.firebaseapp.com',
  projectId: 'impetus-assessportal-dev',
  storageBucket: 'impetus-assessportal-dev.appspot.com',
  messagingSenderId: '920570208668',
  appId: '1:920570208668:web:9ab4bdac73a75c4f272859',
  measurementId: 'G-8198YR8XCR',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
