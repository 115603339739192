import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import * as htmlToImage from 'html-to-image';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexStroke,
  ApexMarkers,
} from 'ng-apexcharts';
import {
  GetQuestionsService,
  IGraphUrls,
} from '../shared-services/get-questions.service';
import { ICategory, ISubmitResponse } from '../assessment/assessment.utils';
import { NotifierToastService } from '../shared-services/notifier-toast.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { CustomAssessmentComponent } from '../custom-assessment/custom-assessment.component';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  yaxis: ApexYAxis;
};
export type SpiderChart = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  tooltip: any;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  colors: string[];
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
};
enum graphTypes {
  barGraph = 'barGraph',
  radarGraph = 'radarGraph',
}
@Component({
  selector: 'app-custom-report',
  templateUrl: './custom-report.component.html',
  styleUrls: ['./custom-report.component.scss'],
})
export class CustomReportComponent implements OnInit {
  public chartOptions: Partial<ChartOptions>;
  public SpiderChart: Partial<SpiderChart>;
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('spider') spider: ChartComponent;
  apiloading = false; //For loader state
  categoryList: ICategory[];
  rating: any;
  reportlink: string; // For final report link
  selectedcat: string; //For user selected category
  message: string; // For final report message
  state: boolean; //For holding validation state of sub_stepper
  emailloading: boolean;
  user_email: any;
  htmlString; // For recommendation table
  date; //Date on which assessment is taken
  radarUrl: string;
  barGraphUrl: string;
  additionalInfo: any;
  currentTimeStamp: any;
  formatedCurrentTime: any;
  chartDataUpdated: boolean = false;
  userEmailAddress: any;
  isReportGenerated = false;
  previewCategory: string = null;
  selectedSubCat: any;
  comparedAttempted: any;
  cat: any;
  @HostListener('window:resize', ['$event'])
  getsize(event?) {
    if (window.innerWidth < 480) {
      return 70;
    } else return 140;
  }
  constructor(
    private getQues: GetQuestionsService,
    private router: Router,
    private nts: NotifierToastService,
    private auth: AuthService,
    private ca: CustomAssessmentComponent
  ) {
    //declaring initial object for creating  bar graph of rating
    this.chartOptions = {
      series: [{ name: 'Actual', data: [] }],
      chart: {
        height: 350,
        fontFamily: 'Roboto',
        type: 'bar',
        toolbar: {
          show: false,
        },
        events: {
          animationEnd: (): void => {
            this.createBarGraphDataUrl();
          },
        },
      },
      plotOptions: {
        bar: {
          rangeBarOverlap: true,
          rangeBarGroupRows: false,
          colors: {
            ranges: [
              {
                from: 0,
                to: 49.99,
                color: '#f54242',
              },
              { from: 50, to: 69.99, color: '#f5a442' },
              { from: 70, to: 101, color: '#1ec756' },
            ],
          },
          horizontal: true,
        },
      },
      colors: [
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
        '#000000',
      ],
      dataLabels: {
        textAnchor: 'start',
        style: {
          colors: [
            function (opts) {
              return '#000000';
            },
          ],
        },
        formatter: function (val: any, opts: any) {
          const goals =
            opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].y;
          return goals + '%';
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
              '#000000',
            ],
          },
          rotate: 0,
          maxWidth: 450,
        },
        tickAmount: 10,
        max: 100,
        min: 0,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: [
          'Poor',
          'Moderate',
          'Good',
          'Min-Expected',
          'Expected',
        ],
        markers: {
          fillColors: ['#f54242', '#f5a442', '#1ec756', '#000', '#775DD0'],
        },
      },
    };
    //declaring initial object for creating  spider graph of rating
    this.SpiderChart = {
      series: [
        {
          name: 'Series 1',
          data: [],
        },
      ],
      chart: {
        height: 350,
        fontFamily: 'Roboto',
        type: 'radar',
        toolbar: {
          show: false,
        },
        events: {
          animationEnd: (): void => {
            this.createRadarGraphDataUrl();
          },
          mounted: (): void => {
            this.customiseRadarGraphDataLabelColors();
          },
          updated: (): void => {
            this.customiseRadarGraphDataLabelColors();
          },
        },
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        radar: {
          size: this.getsize(),
          polygons: {
            strokeColors: '#e9e9e9',
            fill: {
              colors: ['#f8f8f8', '#fff'],
            },
          },
        },
      },
      colors: ['#FF4560'],
      markers: {
        size: 4,
        colors: ['#fff'],
        strokeColors: ['#FF4560'],
        strokeWidth: 2,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: [],
        labels: {
          rotate: -90,
          hideOverlappingLabels: false,
          style: {
            colors: [
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
              '#ffffff',
            ],
          },
        },
        title: {
          style: {
            color: '#fff',
          },
        },
      },
      yaxis: {
        tickAmount: 10,
        max: 100,
        min: 0,
      },
    };
  }

  ngOnInit(): void {
    this.apiloading = true;
    let temp = this.getQues.returnCurrentSubCat();
    this.user_email = this.getQues.getItem('user_email');
    this.selectedcat = temp[2];
    this.cat = temp[0];
    let res = this.getQues.fetchRating().subscribe(
      (data: ISubmitResponse) => {
        // console.log('generateRating data:   ', data);
        this.date = data.date;
        this.message = data.message;
        this.rating = data.rating;
        this.charts();
        this.apiloading = false;
        this.auth.setFalse('report');
      },
      (err: Error) => {
        this.nts.showNotification(
          'error',
          'Unable to generate report. Please try again!'
        );
        console.log(err);
        this.apiloading = false;
      }
    );
  }
  /**
   * Create bar graph and spider chart according to rating data
   * */
  charts() {
    let i = 0;
    let rating = 0;
    this.rating.forEach((element) => {
      let datatemp = {
        x: element.subCategory,
        y: element.rating,
        goals: [
          {
            name: 'Min Expected',
            value: 50,
            strokeWidth: 3,
            strokeColor: '#000000',
          },
          {
            name: 'Expected',
            value: 70,
            strokeWidth: 5,
            strokeColor: '#775DD0',
          },
        ],
      };
      this.chartOptions.series[0].data[i] = datatemp;
      if (element.rating && element.rating != 'NaN') {
        this.SpiderChart.series[0].data[i] = element.rating;
        rating = rating + element.rating;
      } else {
        this.SpiderChart.series[0].data[i] = 0;
        rating = rating + element.rating;
      }
      this.SpiderChart.xaxis.categories[i] = element.subCategory;
      i = i + 1;
    });
    let avgrating = rating / i;
    if (avgrating < 50) {
      this.SpiderChart.colors = ['#FF4560'];
      this.SpiderChart.markers.strokeColors = ['#FF4560'];
    } else if (avgrating < 70) {
      this.SpiderChart.colors = ['#f5a442'];
      this.SpiderChart.markers.strokeColors = ['#f5a442'];
    } else {
      this.SpiderChart.colors = ['#1ec756'];
      this.SpiderChart.markers.strokeColors = ['#1ec756'];
    }
  }

  /**
   * API calling sending report to user via mail
   */
  async email() {
    this.emailloading = true;
    let res = await this.getQues
      .emailreport(this.reportlink, this.user_email, true)
      .subscribe(
        (data: any) => {
          if (data.status) {
            this.nts.showNotification('success', data.message);
            this.emailloading = false;
          } else {
            this.nts.showNotification('error', 'Unable to Sent report');
            this.emailloading = false;
          }
        },
        (err: Error) => {
          this.nts.showNotification('error', 'Unable to Sent report');
          console.log(err);
          this.emailloading = false;
        }
      );
  }

  createBarGraphDataUrl(): void {
    htmlToImage.toBlob(document.getElementById('chart')).then((dataUrl) => {
      this.currentTimeStamp = Date.now();
      this.formatedCurrentTime = new Date().toDateString(); //toDateString();
      this.userEmailAddress = localStorage.getItem('email');
      this.chartDataUpdated = true;
      this.uploadfile(
        dataUrl,
        `${this.userEmailAddress}-${this.currentTimeStamp}.jpeg`,
        graphTypes.barGraph
      );
    });
  }

  createRadarGraphDataUrl(): void {
    htmlToImage.toBlob(document.getElementById('spider')).then((dataUrl) => {
      const currentTimeStamp = Date.now();
      const userEmailAddress = localStorage.getItem('email');
      this.uploadfile(
        dataUrl,
        `${userEmailAddress}-${currentTimeStamp}.jpeg`,
        graphTypes.radarGraph
      );
    });
  }

  uploadfile(file, fileName, graphType: string): void {
    const bucket = new S3({
      accessKeyId: environment.ACCESS_KEY,
      secretAccessKey: environment.SECRET_KEY,
    });
    const params = {
      Bucket: environment.BUCKET,
      Key: `reports/htmltopdfs/${fileName}`,
      Body: file,
    };

    bucket.upload(params, (err: Error, data: S3.ManagedUpload.SendData) => {
      if (err) {
        this.nts.showNotification('error', err.message);
      } else {
        const url = data.Location;
        if (graphType === graphTypes.barGraph) {
          this.barGraphUrl = url;
        } else {
          this.radarUrl = url;
        }
        if (this.barGraphUrl && this.radarUrl) {
          const graphUrls: IGraphUrls = {
            barGraphUrl: this.barGraphUrl,
            radarGraphUrl: this.radarUrl,
          };
          this.getQues
            .submitrecom(
              this.getQues.addrecom,
              this.additionalInfo,
              this.selectedcat,
              this.cat,
              this.rating,
              graphUrls
            )
            .subscribe(
              (response: ISubmitResponse) => {
                this.isReportGenerated = response.report ? true : false;
                this.reportlink = response.report;
              },
              (error: Error) => {
                console.log(error.message);
              }
            );
        }
      }
    });
  }

  downloadReport(): void {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', this.reportlink);
    link.setAttribute('download', this.reportlink);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  homeRedirect() {
    this.router.navigate(['']);
  }

  customiseRadarGraphDataLabelColors(): void {
    const graphElements = document.getElementsByClassName(
      'apexcharts-datalabels'
    )[0].childNodes;
    const textNodeValueList: number[] = [];
    const rectNodeIdList: string[] = [];
    Array.prototype.forEach.call(graphElements, (element) => {
      if (element.nodeName === 'text') {
        const nodeId = element.id;
        const textValue = document.getElementById(nodeId).textContent;
        textNodeValueList.push(parseFloat(textValue));
      }
      if (element.nodeName === 'rect') {
        const val = element.id;
        rectNodeIdList.push(val);
      }
    });
    textNodeValueList.forEach((val, index) => {
      if (val >= 0 && val < 49.99) {
        document.getElementById(rectNodeIdList[index]).style.fill = '#f54242';
      } else if (val >= 50 && val < 69.99) {
        document.getElementById(rectNodeIdList[index]).style.fill = '#f5a442';
      } else if (val >= 70 && val < 101) {
        document.getElementById(rectNodeIdList[index]).style.fill = '#1ec756';
      }
    });
  }

  giveRecommend() {
    window.location.reload();
  }
}
