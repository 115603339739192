<div previewTag class="" style="">
  <div class="report-preview">
    <div class="card-data">
      <div class="card-head">
        <h4 class="what-get">What you get</h4>
      </div>
      <div class="card-body">
        <ol>
          <li>
            Instantly - A preliminary assessment report with:
            <br />
            <ul>
              <li class="blackbullets">
                Recommendations for:<br />
                <ul>
                  <li class="whitebullets">Business strategy</li>
                  <li class="whitebullets">Security and governance</li>
                  <li class="whitebullets">Infrastructure</li>
                  <li class="whitebullets">Operational excellence</li>
                </ul>
              </li>
            </ul>
            <ul>
              <li class="blackbullets">
                In-depth insights on existing strengths and areas for
                improvement
              </li>
            </ul>
          </li>
          <br />
          <li>
            Within 3 business days – A detailed assessment report with:<br />
            <ul>
              <li class="blackbullets">
                Customized insights based on our experience of working with
                Fortune 500 enterprises on large-scale cloud initiatives
              </li>
              <li class="blackbullets">
                Specific recommendations to help reduce cost, optimize resource
                utilization, enhance security, and implement best practices
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
    <div class="report-redirection">
      <a
        style="align-self: center"
        target="_blank"
        href="https://kloudjet-assessment-portal-storage-prod.s3.amazonaws.com/reports/pdfs/selfserviceassessment-1660547750171.pdf"
      >
        <img
          class="report-image"
          src="../../assets/images/graph-report-icon-vector-15801000.png"
        />
      </a>
      <a
        class="report-link"
        target="_blank"
        href="https://kloudjet-assessment-portal-storage-prod.s3.amazonaws.com/reports/pdfs/selfserviceassessment-1660547750171.pdf"
      >
        View sample report
      </a>
    </div>
  </div>
</div>
