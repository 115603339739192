import { Component, OnInit } from '@angular/core';
import { GetQuestionsService } from '../shared-services/get-questions.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-assessment',
  templateUrl: './select-assessment.component.html',
  styleUrls: ['./select-assessment.component.scss'],
})
export class SelectAssessmentComponent implements OnInit {
  apiloading: boolean;
  categoryList: any;
  selected = false;
  selectedId;

  constructor(private router: Router, private getQues: GetQuestionsService) {
    this.apiloading = true;
    this.getcat();
  }

  ngOnInit(): void {
    this.getQues.initial();
  }
  async getcat() {
    await this.getQues.getCat().subscribe((data: any) => {
      this.categoryList = data.assessmentTypes;
      this.apiloading = false;
      this.selected = true;
    });
  }
  selectAssessment(name, id) {
    this.selectedId = id;
    let storedobj = this.getQues.storeobj('empty', {});
    this.getQues.storeInitalCat(this.categoryList, name, id);
  }
  next() {
    this.getQues.initial();
    this.router.navigate(['assessment'], {
      state: {
        isAddInfo: false,
      },
    });
  }
}
