import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifierToastService } from '../shared-services/notifier-toast.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  email: string;
  password: string;
  iseditor = false;
  name: string;
  lastname: string;
  otp = false; //For holding state of form buttons
  submittingForm = false; //For holding state of form buttons
  verifyuser = false; //For holding state of form buttons
  otprecived = false; //For holding state of form buttons
  constructor(
    private router: Router,
    private nts: NotifierToastService,
    private http: HttpClient,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    localStorage.setItem('loggedIn', 'true');
  }
  setItem(name: string, item: string): void {
    localStorage.setItem(name, item);
  }
  async verifyUser() {
    this.verifyuser = true;
    await this.http
      .post(environment.VERIFY_USER, { email: this.email, name: this.name })
      .subscribe(
        (data: any) => {
          if (data.status) {
            // set user email to local storage
            this.setItem('email', this.email);
            // set user role to local storage
            this.setItem('role', data.role);
            this.setItem('name', this.name);
            if (data.role == 'editor') {
              this.iseditor = true;
              this.verifyuser = false;
            } else {
              this.router.navigate(['select-assessment']);
              this.verifyuser = false;
            }
          }
        },
        (err: Error) => {
          this.router.navigate(['select-assessment']);
          this.verifyuser = false;
        }
      );
  }

  async takeassessment() {
    this.router.navigate(['select-assessment']);
  }

  recommendation() {
    this.router.navigate(['custom-recommendation']);
  }
}
