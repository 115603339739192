<div class="bg-div">
  <div class="sb-nav-fixed">
    <app-header></app-header>
  </div>
  <div class="row-1">
    <div class="continue-section">
      <div class="assessment-name">You are taking {{ selectedCat }}.</div>
      <button class="continue-button" (click)="select(continueIndex)">
        Continue Assessment
        <img class="arrow-svg" src="../../assets/images/right-arrow.svg" />
      </button>
    </div>
    <div class="carousel-section">
      <div class="carousel-cards">
        <div class="carousel-text">
          Before Generating Report check what's more <br />to add to the your
          Report
        </div>
        <div class="category-carousel">
          <ul style="margin: 5px 0 0 15px; padding: 0.5vw">
            <div class="carousel carousel-left"></div>
            <li *ngFor="let slide of slides; let i = index">
              <div class="carousel" *ngIf="i == counter" @fade>
                <div class="slides">
                  <div class="">
                    <p class="caption-carousel">
                      {{ allCat.indexOf(slide) + 1 }}. {{ slide }}
                    </p>
                  </div>
                  <div>
                    <div
                      *ngIf="comparedAttempted[slide] == '#ffcece'"
                      class="status unattempted"
                    >
                      <!-- <p class="sub-text">Lorem Epsum Doreum sit un</p> -->
                      <p class="status-text">Unattempted</p>
                      <img
                        class="enter-image"
                        (click)="select(slide)"
                        src="../../assets/images/carousel-next.png"
                        alt="carousel background"
                      />
                    </div>
                    <div
                      *ngIf="comparedAttempted[slide] == '#fffbe1'"
                      class="status partially"
                    >
                      <!-- <p class="sub-text">Lorem Epsum Doreum sit un</p> -->
                      <p class="status-text">Partially Attempted</p>
                      <img
                        class="enter-image"
                        (click)="select(slide)"
                        src="../../assets/images/carousel-next.png"
                        alt="carousel background"
                      />
                    </div>
                    <div
                      *ngIf="comparedAttempted[slide] == '#daebd5'"
                      class="status done"
                    >
                      <!-- <p class="sub-text">Lorem Epsum Doreum sit un</p> -->
                      <p class="status-text">Done</p>
                      <img
                        class="enter-image"
                        (click)="select(slide)"
                        src="../../assets/images/carousel-next.png"
                        alt="carousel background"
                      />
                    </div>
                    <a class="next-cat" (click)="showNextImage()"
                      >Take next category</a
                    >
                  </div>
                </div>
              </div>
            </li>
            <div class="carousel carousel-right"></div>
          </ul>
        </div>
      </div>
      <div class="carousel-image">
        <img
          class="carousel-bg"
          src="../../assets/images/slide-side.png"
          alt="carousel background"
        />
      </div>
    </div>
  </div>

  <div class="row-2">
    <div class="assessment-text">
      <p class="cat-name">{{ selectedCat }} Report</p>
      <p class="cat-subtext">
        Enterprises with existing system at private cloud.
      </p>
      <button class="button generate-button" (click)="addreport()">
        Generate Report<img
          class="arrow-svg"
          src="../../assets/images/right-arrow.svg"
        />
      </button>
    </div>
    <div class="report-image">
      <img
        class="report-image-end"
        src="../../assets/images/graph-report-icon-vector-15801000.png"
      />
    </div>
  </div>
</div>
