import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GetQuestionsService } from 'src/app/shared-services/get-questions.service';
import { Router } from '@angular/router';
import {
  trigger,
  transition,
  animate,
  style,
  state,
} from '@angular/animations';

@Component({
  selector: 'app-select-subcategory',
  templateUrl: './select-subcategory.component.html',
  styleUrls: ['./select-subcategory.component.scss'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0.6 })),
      // transition("void <=> *", [animate("0.5000s ease-in-out")])
      transition('* <=> void', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class SelectSubcategoryComponent implements OnInit {
  allCat: any;
  currentCatIndex: number;
  current = 0;
  counter = 0;
  selectedCat: string;
  continueIndex: string;
  comparedAttempted: {};
  slides: [];
  noWrapSlides = false;
  constructor(private getQues: GetQuestionsService, private router: Router) {}
  attempted: any;
  ngOnInit(): void {
    this.attempted = this.getQues.returnAttemptedObj();
    let temp = this.getQues.returnCurrentSubCat();
    this.allCat = temp[0];
    this.currentCatIndex = temp[1];
    this.continueIndex = this.allCat[this.currentCatIndex + 1];
    let key = this.allCat.slice(this.currentCatIndex + 1);
    this.allCat.forEach((element) => {
      if (!key.includes(element)) {
        key.push(element);
      }
    });
    this.slides = key;
    this.selectedCat = temp[2];
    this.comparedAttempted = this.getQues.compareAttempted();
    if (Object.keys(this.comparedAttempted).length === 0) {
      this.allCat.forEach((ele) => {
        this.comparedAttempted[ele] = '#ffcece';
      });
    }
  }
  select(c) {
    this.getQues.storeNextSubCat(c);
    this.router.navigate(['assessment'], {
      state: {
        isAddInfo: false,
      },
    });
  }

  log(event: any) {}

  plus(index: number) {
    this.current = index + 1;
    if (this.current == this.slides.length) {
      this.current = 0;
    }
  }

  showNextImage() {
    if (this.counter < this.slides.length - 1) {
      this.counter += 1;
    } else this.counter = 0;
  }
  addreport() {
    this.router.navigate(['assessment'], {
      state: {
        isAddInfo: true,
      },
    });
  }
}
