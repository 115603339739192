import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionBase } from './question-base';
import { GetQuestionsService } from '../shared-services/get-questions.service';

@Injectable({ providedIn: 'root' })
export class QuestionControlService {
  constructor(private gqs: GetQuestionsService) {}
  ext: string;
  toFormGroup(questions: QuestionBase<string>[]) {
    const group: any = {};
    let obj = {};
    obj = this.gqs.returnobj();
    questions.forEach((question) => {
      let key = question.question_key;
      if (key in obj) {
        group[key] = new FormControl(obj[key].answer, Validators.required);
      } else {
        group[key] = new FormControl(question.value, Validators.required);
      }
    });
    return new FormGroup(group);
  }
}
