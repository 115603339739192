import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  GetQuestionsService,
  IGraphUrls,
} from '../shared-services/get-questions.service';
import Stepper from 'bs-stepper';
import 'moment';
import * as moment from 'moment';
import { CustomQuestionsComponent } from './custom-questions/custom-questions.component';
import { NotifierToastService } from '../shared-services/notifier-toast.service';
import * as S3 from 'aws-sdk/clients/s3';
import * as htmlToImage from 'html-to-image';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexLegend,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexFill,
  ApexStroke,
  ApexMarkers,
} from 'ng-apexcharts';
import { environment } from 'src/environments/environment';
import { ISubmitResponse } from '../assessment/assessment.utils';
import { Router } from '@angular/router';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  legend: ApexLegend;
  colors: string[];
  yaxis: ApexYAxis;
};
export type SpiderChart = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  title: ApexTitleSubtitle;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  tooltip: any;
  plotOptions: ApexPlotOptions;
  fill: ApexFill;
  colors: string[];
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  xaxis: ApexXAxis;
};

enum graphTypes {
  barGraph = 'barGraph',
  radarGraph = 'radarGraph',
}
@Component({
  selector: 'app-custom-assessment',
  templateUrl: './custom-assessment.component.html',
  styleUrls: ['./custom-assessment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomAssessmentComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('spider') spider: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public SpiderChart: Partial<SpiderChart>;
  firstStepCompleted = false;
  secondStepCompleted = false;
  thirdStepCompleted = false;
  fourthStepCompleted = false;
  fifthStepCompleted = false;
  htmlString; // For recommendation table
  date; //Date on which assessment is taken
  apiloading = false; //For loader state
  assessmentList: string[]; // For Draft assessment list
  private stepper: Stepper;
  result: any;
  stepperlist = []; // For sub_stepper
  x = 0; //For holding current index of sub_stepper
  cat = []; //For holding sub categories
  lastindex = 0; //For total number of sub_category
  frm: any;
  rating: any;
  reportlink: string; // For final report link
  selectedcat: string; //For user selected category
  message: string; // For final report message
  @Input() form: any;
  user_email: string;
  state: boolean; //For holding validation state of sub_stepper
  emailloading: boolean;
  allCategory = [];
  inputValue: any;
  storedobj: any;
  radarUrl: string;
  barGraphUrl: string;
  isReportGenerated = false;
  additionalInfo: any;
  recommendations: any;
  isReport = false;
  assessment_id: any;
  allSubCat = [];

  constructor(
    private getQues: GetQuestionsService,
    private nts: NotifierToastService,
    private router: Router
  ) {
    this.apiloading = true;
    this.getassessment();
  }

  /**
   * checking validation of sub steppers
   */
  valid(currentstate: boolean) {
    this.state = currentstate;
  }
  strfrm(frm: any) {
    this.frm = frm;
  }
  get isValid() {
    return this.state;
  }
  /**
   * API call for fetching all Draft Assessment
   *  */
  async getassessment() {
    let email = this.getQues.getUser();
    await this.getQues.getDraft(email).subscribe((data: any) => {
      // console.log(data)
      this.assessmentList = data.assessments.sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      );
      this.apiloading = false;
      this.assessmentList.forEach((element: any) => {
        element['date'] = moment
          .unix(element.timestamp / 1000)
          .locale('en-in')
          .format('MMMM Do, YYYY');
        element['time'] = moment
          .unix(element.timestamp / 1000)
          .locale('en-in')
          .format('LTS');
      });
    });
  }
  /**
   * Intiallizing steppers
   */
  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    this.message = '';
  }
  step1() {
    this.apiloading = true;
    this.getassessment();
    (document.getElementById('addrecom') as HTMLInputElement).value = '';
  }
  /**
   *
   * Fetching Draft assessment corresponding to selected assessment ID
   * @param Category // Category of selected draft assessment
   * @param assessment_id // Id of selected draft assessment
   * @param user_email // Email of selected draft assessment user
   * @param category_id // ID of selected category of draft assessemnt
   */
  async selectAssessment(
    Category: string,
    assessment_id,
    user_email,
    category_id,
    name
  ) {
    this.message = '';
    this.selectedcat = Category;
    this.isReportGenerated = false;
    this.getQues.initial();
    this.apiloading = true;
    this.stepper.next();
    this.getQues.setItem('user_email', user_email);
    this.getQues.setItem('user_name', name);
    this.user_email = this.getQues.getItem('user_email');
    //API call for ques in user selected category
    // console.log(assessment_id)
    this.assessment_id = assessment_id;
    await this.getQues
      .getAssessment(Category, assessment_id, category_id)
      .subscribe(
        (data: any) => {
          // console.log(data)
          this.apiloading = false;
          this.result = data.assessments;
          this.x = 0;
          let storedobj = this.getQues.storeobj(
            'editor',
            data.draftedAssessment
          );
          this.inputValue = (<HTMLInputElement>(
            document.getElementById('addinfo')
          )).value;
          this.inputValue = data.additionalDetails;
          // Array for all sub_category
          this.allCategory = Object.keys(this.result);
          for (const key in data.draftedAssessment) {
            let temp = data.draftedAssessment[key];
            if (!this.cat.includes(temp.subCategory)) {
              this.cat.push(temp.subCategory);
            }
          }
          this.lastindex = this.cat.length - 1;
          let i = 0;
          for (const key in data.assessments) {
            if (Object.prototype.hasOwnProperty.call(data.assessments, key)) {
              const temp = data.assessments[key];
              // console.log(data)
              // console.log(temp)
              this.allSubCat.push({
                subCategoryName: temp[0].sub_category,
                subCategoryId: temp[0].sub_category_id,
              });
            }
          }
          this.cat.forEach((element) => {
            let stepresult = this.result[element];
            //Array of question grouped by sub_category for sub_steppers
            this.stepperlist[i] = stepresult;
            // this.allSubCat.push({
            //   "subCategoryName": element,
            //   "subCategoryId": stepresult[0].sub_category_id
            // })
            i = i + 1;
          });
          this.getQues.storeInitalCat(this.allSubCat, Category, this.x);
          this.getQues.storeAllcategories(this.allSubCat);
          this.getQues.storeCurrentSubCat(this.allCategory, this.x);
        },
        (err: Error) => {
          console.log(err);
          this.nts.showNotification(
            'error',
            'Unable to generate assessment. Please try again!'
          );
          this.apiloading = false;
          this.stepper.previous();
        }
      );
  }

  /**
   * next stepper
   */
  next() {
    this.stepper.next();
    window.scroll(0, 0);
  }

  /**
   * next question category sub_stepper
   */
  nextq() {
    this.x = this.x + 1;
    this.state = false;
    window.scroll(0, 0);
    this.getQues.storeCurrentSubCat(this.allCategory, this.x);
  }
  /**
   * previous stepper
   */
  stepBack() {
    this.stepper.previous();
    window.scroll(0, 0);
    this.x = 0;
  }
  /**
   * previous question category sub_stepper
   */
  prevq() {
    this.x = this.x - 1;
    window.scroll(0, 0);
  }
  /**
   *  submitting complete assessment
   * @param addrecom //additional recommendation
   * @param addinfo //additional information
   */
  async submit2(addrecom, addinfo: any) {
    this.apiloading = true;
    this.storedobj = this.getQues.returnobj();
    if (addinfo == null) {
      addinfo = '';
    }
    window.scroll(0, 0);
    let res = this.getQues.generateRatingRec(
      this.selectedcat,
      addinfo,
      addrecom,
      this.assessment_id
    );
    this.stepper.next();
    this.isReport = true;
  }
}
