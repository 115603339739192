import { Component, Injectable, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { ReportComponent } from '../report/report.component';
@Component({
  selector: 'app-preview-section',
  templateUrl: './preview-section.component.html',
  styleUrls: ['./preview-section.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class PreviewSectionComponent implements OnInit {
  closeResult = '';
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}
  open() {
    const dialogRef = this.dialog.open(ReportComponent);
  }
}
