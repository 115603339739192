import { Injectable, Input } from '@angular/core';
import { RadioQuestion } from './questions-radio';
import { checkQuestion } from './questions-checkbox';
import { DropdownQuestion } from './question-dropdown';
import { QuestionBase } from './question-base';
import { TextboxQuestion } from './question-textbox';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  @Input() ques;

  constructor() {}
  getQuestions() {
    const questions: QuestionBase<string>[] = [];
    for (const q in this.ques) {
      if (Object.prototype.hasOwnProperty.call(this.ques, q)) {
        const element = this.ques[q];
        if (element.type == 'radio') {
          questions.push(
            new RadioQuestion({
              question_key: element.question_key,
              question: element.question,
              options: element.options,
              type: element.type,
            })
          );
        } else {
          questions.push(
            new checkQuestion({
              question_key: element.question_key,
              question: element.question,
              options: element.options,
              type: element.type,
            })
          );
        }
      }
    }

    return questions;
  }
}
