import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { QuestionService } from '../question.service';
import { QuestionBase } from './../question-base';
import { QuestionControlService } from './../question-control.service';
import { GetQuestionsService } from 'src/app/shared-services/get-questions.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CustomAssessmentComponent } from '../custom-assessment.component';
@Component({
  selector: 'app-custom-questions',
  templateUrl: './custom-questions.component.html',
  styleUrls: ['./custom-questions.component.scss'],
  providers: [QuestionControlService],
})
export class CustomQuestionsComponent implements OnInit {
  @Input() ques: any;
  closeResult = '';
  @Input() formname: any;
  @Input() subhead: string;
  @Output() formvalid = new EventEmitter<boolean>();
  @Output() compform = new EventEmitter<any>();
  @Input() userfrm: any;
  state: boolean;
  x = 0;
  lastindex = 0;
  qno = 0;
  lastno = 0;
  other: boolean = false; // to enable input text field
  storedobj: any; //hold entire stored object in GetQuestionsService
  catResponse: any; //hold entire recommendations for each subcategory from stored object in GetQuestionsService
  current_que: string; // hold current question
  index = {};
  tag = {};
  constructor(
    private formBuilder: FormBuilder,
    private qcs: QuestionControlService,
    private qs: QuestionService,
    private gqs: GetQuestionsService,
    private modalService: NgbModal,
    private ca: CustomAssessmentComponent
  ) {
    this.ques = qs.getQuestions();
  }
  //form validation
  isValid() {
    this.formvalid.emit(true);
    this.compform.emit(this.formname);
    return;
  }

  async ngOnInit() {
    this.x = this.ca.x;
    this.lastindex = this.ca.lastindex;
    this.formname = this.qcs.toFormGroup(this.ques as QuestionBase<string>[]);
    let temp = this.ques;
    this.ques = [];
    temp.forEach((q) => {
      //initializing only attempted ques
      if (this.formname.controls[q.question_key].value) {
        this.ques.push(q);
      }
    });
    this.lastno = this.ques.length - 1;
    this.storedobj = this.gqs.returnobj();
    this.catResponse = this.gqs.returncat();
    //initializing index and tags object if they exists
    try {
      if (this.storedobj) {
        for (let key in this.storedobj) {
          this.index[key] = this.storedobj[key].answer_key;
          this.tag[key] = this.storedobj[key].tag;
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.isValid();
  }

  /**
   * Halding Text input from recommendation option
   * @param event //selection event
   * @param object //unique question key
   * @param question //question
   */
  updateValue(event, object: string, question: string) {
    // Response object
    let res = {
      question: question,
      answer: this.formname.controls[object].value,
      subCategory: this.subhead,
      question_key: object,
      answer_key: this.index[object],
      tag: this.tag[object],
      recommendation: event.target.value.trim(),
    };
    console.log(res);
    this.storedobj = this.gqs.storeobj(object, res);
    this.isValid();
  }
  /**
   * Halding Text input from sub category recommendation option
   * @param event //selection event
   * @param object //sub category
   */
  updateCat(event, object) {
    this.catResponse = this.gqs.storeCat(object, event.target.value.trim());
  }
  prevq() {
    this.qno--;
    this.current_que = this.ques[this.qno].question;
  }
  nextq() {
    this.qno++;
    this.current_que = this.ques[this.qno].question;
  }
  next() {
    this.ca.next();
    this.modalService.dismissAll('Cross click');
  }
  nextqCat() {
    this.ca.nextq();
    this.modalService.dismissAll('Cross click');
  }
  open(content) {
    this.x = this.ca.x;
    this.lastindex = this.ca.lastindex;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
