import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NotifierToastService } from './shared-services/notifier-toast.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private nts: NotifierToastService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // return true;
    if (this.auth.isLoggedIn()) {
      return true;
    } else if (localStorage.getItem('page')) {
      // console.log(localStorage.getItem('page'))
      // console.log('in')
      this.nts.showNotification('alert', 'Click on Impetus logo to redirect!');
      return false;
    }
    return false;
  }
}
